import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userActions, alertActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { LuCopyCheck, LuCopy } from "react-icons/lu";
import { AiFillDelete } from "react-icons/ai";
import { elements } from 'chart.js';
import { Helmet } from 'react-helmet';

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,


    }
  }


  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10,

    }
    this.props.dispatch(userActions.getUserNotificationList(data));
    this.props.dispatch(userActions.redAllNotification());
  }

  deleteUser = (data) => {
    console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data._id,
    }
    let paginationData = {
      // "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteNotification(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getUserNotificationList(datatemp));
  }







  render() {

    let { users } = this.props;
    let { loading, getNotificationDataList, getNotificationDatatotal } = users ? users : {};



    return (

      <>
   <Helmet>
          <title>VuneCoin - notification</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
          <section>
            <div className='overflow-hidden'>
              <div className='overflow-y-auto p-10 space-y-4'>
                {getNotificationDataList && getNotificationDataList.length > 0 ? getNotificationDataList.map((elements, index) => (
                  <div key={index} className="border border-[#5B4DCA] rounded-[14px] p-6 flex justify-between items-center">
                    <div className='flex items-center space-x-4'>
                      <div>
                        <img src="/img/logo.png" alt="logo" className='rounded-full border-2 border-[#5B4DCA] w-20 h-20' />
                      </div>
                      <div>
                        <p className='text-white text-xl font-bold'>{elements && elements.name ? elements.name : "--"}</p>
                        <p className='text-white text-lg font-semibold' >{elements && elements.msg ? elements.msg : "---"}</p>
                        <p className='text-white text-sm'>{moment(new Date(parseInt(elements && elements.createdAt ? elements.createdAt : "-"))).utcOffset("+05:30").format("HH:mm DD-MM-YYYY")}</p>
                      </div>
                    </div>
                    <div className=''>
                      <button className="font-medium flex justify-center items-center " onClick={() => this.deleteUser(elements)}><AiFillDelete size={30} className='text-red-600 hover:text-red-400' /></button>
                    </div>
                  </div>
                )) : null}


              </div>
            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(Transaction);
