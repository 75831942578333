import React, { useState } from 'react';
import Modal from 'react-modal';
import { ImCross } from "react-icons/im";
import { GoCopy } from "react-icons/go";
import { LuCopyCheck, LuCopy } from "react-icons/lu";
import { alertActions } from '../../../../_actions';

import QRCode from "react-qr-code";



export default function ReceiveModal({ handleCloseReceive, handleOpenPayment, props, walletAdd }) {
  const [copiedTxId, setCopiedTxId] = useState(null);

  const copyTxIdToClipboard = (walletAdd) => {
    const tempInput = document.createElement('input');
    tempInput.value = walletAdd;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setCopiedTxId(walletAdd);
    props.dispatch(alertActions.success("Copied!"));
  };


  console.log("walletAdd", walletAdd);

  return (
    <>
      {/* <div className='flex flex-col justify-center items-center'>

        <div className={handleOpenPayment ? "w-[350px] md:w-[600px] fixed  z-40 top-28  " : "hidden"} style={{ marginTop: '0rem', }}  >
          <div className=" rounded bg-[#1B1C1D]">
            <div className='bg-[#E05D2D] px-4 py-2 flex justify-between items-center text-white rounded-t'>
              <h1>Receive Coin</h1>
              <button onClick={() => handleCloseReceive()}>
                <ImCross className='text-white' size={16} />
              </button>
            </div>
            <div className='bg-[#1B1C1D] px-2 py-4 justify-between items-center text-gray-200 rounded'>

              <div className='flex justify-center items-center p-20 '>
                {walletAdd ?
                  <div className='border border-gray-600'>
                    <QRCode
                      size={256}
                      value={walletAdd}
                      className='w-64 h-64'
                    />
                  </div> : null}
              </div>


              <div className='px-6'>

                <div className='flex justify-between items-center border bg-transparent border-gray-400 text-gray-400 lg:text-sm text-[10px] rounded-lg    w-full p-2.5  '>
                  <span>{walletAdd && walletAdd}</span> <span className=''>
                    <button
                      onClick={() => copyTxIdToClipboard(walletAdd)}
                      className="ml-2 text-center text-blue-500 cursor-pointer"
                      disabled={copiedTxId === walletAdd}
                    >
                      {copiedTxId === walletAdd ? <LuCopyCheck className='w-7 h-7 text-[#007F00]' /> : <GoCopy className='w-7 h-7' />}
                    </button>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className={handleOpenPayment ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="md:w-[32rem] w-11/12  mx-auto rounded-[24px] shadow-lg  overflow-y-auto mt-2">
          {/*modal header*/}
          <div className="flex items-center justify-between p-6 py-2 bg-[#1C2745]">
            <p className="text-xl font-bold text-white uppercase invisible">Receive Coin</p>
            <div className="" onClick={() => handleCloseReceive()}>
              <ImCross className='text-white' size={16} />
            </div>
          </div>

          <div className='bg-[#1C2745] px-2 py-4 justify-between items-center text-gray-200 rounded'>

            <div className='flex justify-center items-center p-12'>
              {walletAdd ?
                <div className='border border-gray-600'>
                  <QRCode
                    size={256}
                    value={walletAdd}
                    className='w-64 h-64'
                  />
                </div> : null}
            </div>


            <div className='px-6'>

              <div className='flex justify-between items-center border bg-transparent border-gray-400 text-gray-400 lg:text-sm text-[10px] rounded-lg w-full p-2.5'>
                <span>{walletAdd && walletAdd}</span> <span className=''>
                  <button
                    onClick={() => copyTxIdToClipboard(walletAdd)}
                    className="ml-2 text-center text-blue-500 cursor-pointer"
                    disabled={copiedTxId === walletAdd}
                  >
                    {copiedTxId === walletAdd ? <LuCopyCheck className='w-7 h-7 text-[#007F00]' /> : <GoCopy className='w-7 h-7' />}
                  </button>
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>

  );
}
