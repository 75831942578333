import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "name": "",
      "email": "",
      "mobile": "",
      "message": "",
      shown: false,
    }
  }
  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  handleSubmit = () => {
    let { name, email, mobile, message } = this.state;
    let reqData = { name, email, mobile, message };
    // console.log("reqData  ", reqData);
    this.props.dispatch(userActions.createNotification(reqData, this.props));
  }
  componentDidMount() {
    // this.props.dispatch(userActions.statics());
  }

  render() {
    return (
      <>
        <div className='h-screen overflow-y-auto'>


        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Contactus);