import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";
import { AiOutlineMail } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import NetworkDetector from '../../components/Hoc/NetworkDetector';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    // this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      showPassword: false,
      rememberMeChecked: false
    }
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.otpSentLogin) {
      return {
        ...nextProps,
        otpSentLogin: nextProps.otpSentLogin ? nextProps.otpSentLogin : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }



  inputChange(e) {
    if (e) {
      e.preventDefault();
      const { name, value } = e.target;
      this.setState({ [name]: value });
      let fieldslogin = this.state.fieldslogin;
      let errorslogin = this.state.errorslogin;
      fieldslogin[name] = value;
      errorslogin[name] = "";
      this.setState({ fieldslogin, errorslogin });
    }
  }



  loginSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    if (this.handleValidationLogin()) {
      let { email, password } = this.state;
      this.props.dispatch(
        userActions.login({ email: email, password: password }, this.props)
      );
    }
  }

  resendSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //email
    if (!fieldslogin["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Enter email";
    }

    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Enter password";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
    const rememberMeChecked = localStorage.getItem('rememberMeChecked') === 'true';
    this.setState({ rememberMeChecked });
  }

  onClickMenu = (url) => {
    // console.log("url:", url);
    this.props.history.push(url)
  }




  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleCheckboxChange = (event) => {
    const { checked } = event.target;
    this.setState({ rememberMeChecked: checked });
    // Save preference to local storage
    localStorage.setItem('rememberMeChecked', checked);
  };

  render() {
    let { loading } = this.props;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>
          <section className="flex items-center w-full">
            <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full relative lg:block hidden">
              <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
              <div className="flex flex-col justify-center items-center h-screen">
                <div>
                  <img src="/img/imgpsh_fullsize_anim.png" className=" w-72 h-72" alt="" />
                </div>
                <div className="border-t border-[#FFFFFF4D] py-6">
                  <h1 className="text-white text-[60px] font-[700] text-center">Welcome to VuneCoin</h1>
                  <p className="text-[#FFFFFFA1]/60 text-center text-[26px] font-[400]">Please log in with your details</p>
                </div>
                <div className="flex justify-center items-center absolute bottom-4">
                  <a href="https://vuneco.com/" target="_blank" rel="noreferrer">
                    <img src="/img/product-icon.png" alt="" />
                  </a>
                </div>
              </div>
              <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
            </div>
            <div className="bg-[#1C2745] w-full ">
              <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-4 xl:w-3/6 h-screen">
                <div className="text-center">
                  <h1 className="text-[45px] font-[700] text-white">Log <span className="text-[#4F91F5]">in!</span></h1>
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium text-white">Email address</label>
                  <div className="relative flex items-center">
                    <AiOutlineMail className="absolute right-3 text-gray-400" />
                    <input disabled={this.state.otpSentLogin} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="email" name="email" placeholder="example@gmail.com" type="email" onChange={this.inputChange} onKeyPress={(e) => { if (e.key === "Enter") { this.loginSubmit(); } }} />
                  </div>
                  {this.state.errorslogin["email"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["email"]}</div> : null}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-white">Password</label>
                  <div className="relative flex items-center">
                    <input disabled={this.state.otpSentLogin} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="password" name="password" placeholder="Password" type={this.state.showPassword ? "text" : "password"} onChange={this.inputChange} onKeyPress={(e) => { if (e.key === "Enter") { this.loginSubmit(); } }} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }} />
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      {this.state.showPassword ? <FaEye className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} /> : <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} />}
                    </div>
                  </div>
                  {this.state.errorslogin["password"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["password"]}</div> : null}
                </div>

                <div className="flex items-center justify-between px-2 mt-2">
                  <div>

                  </div>
                  {/* <div className="flex items-center cursor-pointer">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="h-4 w-4 cursor-pointer text-[#c8c5d4] focus:ring-[#9c8fe6] border-gray-300 rounded"
                      checked={this.state.rememberMeChecked}
                      onChange={this.handleCheckboxChange}
                    />
                    <label htmlFor="remember_me" className="block ml-2 text-sm text-[#c8c5d4]">
                      Keep me signed in
                    </label>
                  </div> */}
                  <div className="text-sm cursor-pointer" onClick={() => this.onClickMenu('forgot')}>
                    <h2 className="font-medium text-[#FFD600] hover:underline">Forgot password?</h2>
                  </div>
                </div>

                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="submit" onClick={(e) => this.loginSubmit(e)}>Sign in</button>
                </div>
                <div className="py-6">
                  <p className="text-center text-[18px] font-[400] text-[#A09DC5]"> Don´t have an account ? <span onClick={() => this.onClickMenu('/signup')} className="text-[#508EF3] cursor-pointer">Register</span></p>
                </div>
              </div>

            </div>
          </section>
        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Login);