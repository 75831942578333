import React from "react";

import Modal from 'react-modal';
import { ImCross } from "react-icons/im";
import { MdRadioButtonChecked } from "react-icons/md";
import { RxCountdownTimer, RxCross2 } from "react-icons/rx";


export default function TetherModal(props) {

  let { handleOpenTether, handleCloseTether } = props;
 
  return (

    <div className={handleOpenTether ? "w-[400px] md:w-[600px] fixed  z-40 top-10 md:top-16 left-2 md:left-[100px] lg:left-[400px] xl:left-[500px] 2xl:left-[800px]" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className=" rounded bg-[#1B1C1D]">
        <div className='bg-[#E05D2D] px-4 py-2 flex justify-between items-center text-white rounded-t'>
          <h1>Tether USDT</h1>
          <button onClick={() => handleCloseTether()}>
            <ImCross className='text-gray-500' size={16} />
          </button>
        </div>
        <div className='bg-[#1B1C1D] px-2 py-4 justify-between items-center text-gray-200 rounded'>

          <div className=' flex items-center justify-center'>
            <div className='relative flex items-center justify-center w-10 h-10 text-center md:w-16 md:h-16 bg-yellow-600 rounded-full'>
            <img src='/assets/light.png' alt='usdt.png' className='w-6 h-10 object-cover' />
            </div>
          </div>
          <span className="font-bold text-2xl flex justify-center items-center py-2">0</span>

          <div className="space-y-4 p-4">
            <div>
              <select className=" border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   block w-full p-2.5    dark:text-white "
                id="gender"
                value=''
                name="gender">
                <option value="">Select Item</option>
                <option value="Investment">Investment</option>
                <option value="Commercial">Commercial</option>
                <option value="Exchange">Exchange</option>
              </select>
            </div>

            <div>
              <label htmlFor="" className="py-0.5"> Receving Account</label>
              <input className=" border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   block w-full p-2.5    dark:text-white "
                type="text"
                name="Account" placeholder="Enter the receving account"/>
             
            </div>

            <div>
              <label htmlFor="" className="py-0.5"> Amount</label>
              <input className=" border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   block w-full p-2.5    dark:text-white "
                type="text"
                name="Amount" placeholder="Enter the amount">
              </input>
            </div>

            <div className="flex justify-center items-center">
              <button className=' cursor-pointer rounded-full all-btn-color px-12 py-3 space-x-2'>
                <span className=" text-base font-bold flex justify-center items-center text-white "> Submit</span>
              </button>
            </div>

          </div>


        </div>
      </div>
    </div>


 



  );
}
