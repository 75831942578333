import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, dashboardActions } from '../../_actions';
import { alertActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { CONST } from '../../_config';
import {
  BsLink,
} from "react-icons/bs";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon, WhatsappIcon } from "react-share";
import { BiCopy } from "react-icons/bi";

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
    }
  }



  componentDidMount() {
    let data = {
      // "txType": "SEND_RECEIVED",
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(userActions.getUserDetails());
    this.props.dispatch(userActions.getTx(data));
    // this.props.dispatch(dashboardActions.getUserPackagesTeam());
    // this.props.dispatch(dashboardActions.getUserPackagesTeamTotalCount());
    // window.scrollTo(0, 0)
  }

  sendCoin = (e) => {
    e.preventDefault();
    if (this.handleValidationSendCoin()) {

      let { coinType, toAddress, recievingAmount } = this.state.fieldslogin;
      this.props.dispatch(userActions.sendCoin({
        coinType: coinType,
        amount: Number(recievingAmount),
        to: toAddress,
      }, this.props));
    }
  }

  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting;
    if (event.target.value >= 0) {
      const { fieldslogin, errorslogin } = this.state;
      fieldslogin[event.target.name] = event.target.value;
      fieldslogin['amount'] = event.target.value;
      fieldslogin['fees'] = txFee;
      fieldslogin['recievingAmount'] = parseFloat(event.target.value - txFee).toFixed(8);
      errorslogin['amount'] = "";
      this.setState({ fieldslogin, errorslogin });
    } else {
      const { errorslogin } = this.state;
      errorslogin['amount'] = "Amount should be positive number.";
      this.setState({ errorslogin });
    }
  }

  handlePageChange = (offset, page) => {
  }

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "txType": "SEND_RECEIVED",
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }


  onClickMenu = () => {
    this.props.history.push("/app/referalTable")
  }


  onClickMenuTeam = (id) => {
    this.props.history.push("/app/userPackagesTeam/" + id)
  }

  render() {
    let { users, dashboard } = this.props;
    let { loading } = users;
    let { overview } = users;
    let { user } = overview ? overview : {};
    let { getUserPackagesTeamTotalCount } = dashboard;

    // let { wallet } = overview ? overview : {};
    // let { setting } = overview ? overview : {};
    let { refCode, refCount } = user ? user : {};


    // console.log('getUserPackagesTeamTotalCount____________________', getUserPackagesTeamTotalCount);
    // console.log('RENDER_______#wallet::', wallet);
    // console.log('RENDER_______#setting::', setting);
    // console.log('RENDER_______refCount::', refCount);

    return (
      <>
        {/* <Header name="Referral" /> */}
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <main className="relative z-0 flex-1 overflow-y-auto bg_page focus:outline-none" tabIndex={0}>
          <div className="p-6">

            <div className='my-6'>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                <div className="flex-grow p-5 shadow-lg referral-card-top">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <div className="mr-5">
                          <img className="rounded-full" src="assets/refrral1.svg" width={84} height={84} alt="User 01" />
                        </div>
                        <div className="pr-1 mt-1">
                          <div className="inline-flex text-white">
                            <h2 className="justify-center text-2xl font-semibold leading-snug">Total Partner</h2>
                          </div>
                          <div className="flex items-center pt-2 pl-10 text-2xl font-semibold text-white">0</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 shadow-lg referral-card-top">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <div className="mr-5">
                          <img className="rounded-full" src="assets/refrral1.svg" width={84} height={84} alt="User 01" />
                        </div>
                        <div className="pr-1 mt-1">
                          <div className="inline-flex text-white">
                            <h2 className="justify-center text-2xl font-semibold leading-snug">Total Team</h2>
                          </div>
                          <div className="flex items-center pt-2 pl-10 text-2xl font-semibold text-white">0</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 shadow-lg referral-card-top">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <div className="mr-5">
                          <img className="rounded-full" src="assets/refrral1.svg" width={84} height={84} alt="User 01" />
                        </div>
                        <div className="pr-1 mt-1">
                          <div className="inline-flex text-white">
                            <h2 className="justify-center text-2xl font-semibold leading-snug">Total Volume</h2>
                          </div>
                          <div className="flex items-center pt-2 pl-10 text-2xl font-semibold text-white">0</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>

              </div>
            </div>

            <div className="p-5 bg-white rounded-lg shadow-xl 2xl:p-10">

              <div className="mt-3 rounded-md ">
                <h1 className="pt-2 text-xl font-medium text-center all-txt-color lg:text-3xl">Share Referral link with friends </h1>
                <div style={{ height: "1px" }} className="mt-1 ml-8 mr-8 bg-white"></div>

                <div class="flex items-center justify-center md:w-4/6 w-full mx-auto shadow-md mt-8 bg-[#F1F1F1] rounded-lg" onClick={() => this.copyCodeToClipboard(`${CONST.BACKEND_REFERRAL_URL}${refCode}`)}>
                  <input disabled type="text"
                    value={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                    // value="https://GCN-coin.com/121251154/##2@#@@#"
                    class="text-lg w-full p-3 bg-[#F1F1F1] text-[#3B82F6;] rounded-l-lg sm:w-2/3 outline-none" />
                  <button type="button" class=" whitespace-nowrap p-3 font-semibold text-lg rounded-r-lg sm:w-1/3 bg-[#3B82F6]/10 m-2 rounded-lg text-[#3B82F6;] flex items-center justify-center pl-4"><span className='pr-4 space-x-4'><BiCopy className='w-5 h-5' /></span> Copy Link</button>
                </div>

                <div className="w-full mx-auto mt-10">
                  <div className='flex justify-center w-full mx-auto md:w-3/6'>
                    <div className='grid grid-cols-4 gap-2'>

                      <div>
                        <WhatsappShareButton className='focus:outline-none'
                          title={"Hurry, sign up by using this link:"}
                          url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded-full items-center text-center lg:mr-24 cursor-pointer">
                            <WhatsappIcon className="relative text-white rounded-full" size={60} />

                          </div>
                        </WhatsappShareButton>
                      </div>

                      <div>
                        <FacebookShareButton className='focus:outline-none'
                          title={"Hurry, sign up by using this link:"}
                          url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                          hashtag={"facebook"}
                          quotes={"Hurry, sign up by using this link:"}
                          // url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                          description={"Hurry, sign up by using this link:"}
                        // url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded-full items-center text-center lg:mr-24  cursor-pointer">
                            <FacebookIcon className="relative bg-green-500 rounded-full lg:ml-3" size={60} />

                          </div>
                        </FacebookShareButton>
                      </div>

                      <div>
                        <TwitterShareButton className='focus:outline-none'
                          title={"Hurry, sign up by using this link:"}
                        url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded-full items-center text-center lg:mr-24 cursor-pointer">
                            <TwitterIcon className="relative text-white rounded-full" size={60} />

                          </div>
                        </TwitterShareButton>
                      </div>

                      <div>
                        <EmailShareButton className='focus:outline-none'
                          url={`${CONST.BACKEND_URl_REFERRAL}${refCode}`}
                          subject="Bit-Learn"
                          body={"Hurry, sign up by using this link:"
                            + <a href={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}>Link</a>
                          }

                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded-full items-center text-center lg:mr-24 cursor-pointer">
                            <EmailIcon className="relative text-white rounded-full" size={60} />
                          </div>
                        </EmailShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='p-6 mt-6 bg-white rounded-lg shadow-lg'>
              <h2 className='pb-3 text-xl font-medium all-txt-color'>All Infrastructure</h2>
              <div className="grid grid-cols-1 gap-6 2xl:grid-cols-4 lg:grid-cols-2">
                <div className="flex-grow p-5 referral-card">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <a className="mr-5" href="#0">
                          <img className="rounded-full" src="assets/group1.svg" width={64} height={64} alt="User 01" />
                        </a>
                        <div className="pr-1 mt-1">
                          <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                            <h2 className="justify-center text-sm leading-snug">Total Partner</h2>
                          </a>
                          <div className="flex items-center pt-2 pl-10 text-xl font-semibold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.todayTeamCount : "0"}</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 referral-card">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <a className="mr-5" href="#0">
                          <img className="rounded-full" src="assets/group2.svg" width={64} height={64} alt="User 01" />
                        </a>
                        <div className="pr-1 mt-1">
                          <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                            <h2 className="justify-center text-sm leading-snug">Total Team</h2>
                          </a>
                          <div className="flex items-center pt-2 pl-10 text-xl font-semibold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.totalTeamVolumeToday : "0"}</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 referral-card">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <a className="mr-5" href="#0">
                          <img className="rounded-full" src="assets/group3.svg" width={64} height={64} alt="User 01" />
                        </a>
                        <div className="pr-1 mt-1">
                          <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                            <h2 className="justify-center text-sm leading-snug">Total Volume</h2>
                          </a>
                          <div className="flex items-center pt-2 pl-10 text-xl font-semibold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.directVolume : "0"}</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 referral-card">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <a className="mr-5" href="#0">
                          <img className="rounded-full" src="assets/group4.svg" width={64} height={64} alt="User 01" />
                        </a>
                        <div className="pr-1 mt-1">
                          <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                            <h2 className="justify-center text-sm leading-snug">Direct Volume</h2>
                          </a>
                          <div className="flex items-center pt-2 pl-10 text-xl font-semibold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.directVolume : "0"}</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 referral-card">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <a className="mr-5" href="#0">
                          <img className="rounded-full" src="assets/group5.svg" width={64} height={64} alt="User 01" />
                        </a>
                        <div className="pr-1 mt-1">
                          <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                            <h2 className="justify-center text-sm leading-snug">Team Volume</h2>
                          </a>
                          <div className="flex items-center pt-2 pl-10 text-xl font-semibold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.totalTeamVolume : "0"}</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div className="flex-grow p-5 referral-card">
                  <div className="flex items-start justify-between">
                    <header>
                      <div className="flex mb-2">
                        <a className="mr-5" href="#0">
                          <img className="rounded-full" src="assets/group6.svg" width={64} height={64} alt="User 01" />
                        </a>
                        <div className="pr-1 mt-1">
                          <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                            <h2 className="justify-center text-sm leading-snug">User Packages Team</h2>
                          </a>
                          <div className="flex items-center pt-2 pl-10 text-xl font-semibold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.teamCount : "0"}</div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </main >
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, dashboard } = state;
  return {
    users,
    authentication,
    dashboard
  };
}
export default connect(mapStateToProps)(Referral);

