import React, { Component } from 'react'
import { MdClose } from "react-icons/md";
import { connect } from 'react-redux';
import { userActions, alertActions } from '../../_actions';
import { LuCopyCheck } from "react-icons/lu";
import { GoCopy } from "react-icons/go";

export class AddContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldslogin: {},
      errorslogin: {},
      option: {},
      showLeaderSection: false,
      activeTab: 1,
      isPackageListOpen: true,
      isHistoryOpen: false,
      isBioPreservation: false,
      isCertificates: false,
      isHovered: false,
      isNetwork: true,
      isTether: false,
      isPayment: false,
      fieldTicketId: false,
      transactionModal: false,
      openText: false,
      clickedOutside: 0,
      clickedOutBanking: false,
      isReceive: false,
      addressName: '',
      toAddress: '',
      amount: '',
      coin: { symbol: 'GCN' }, // Replace with your initial coin state
      loader: false,
      coinSelect: '',
      cAddress: '',
      amount1: '',
      walletName: ''
    }
  }


  contact_address = () => {
    const { cAddress } = this.state;
    const { users } = this.props;
    let { getAddressList } = users;


    let data = {
      "cAddress": cAddress,
      "address": this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : null
    }


    console.log("addContractAddressaddContractAddressaddContractAddress", data);
    this.props.dispatch(userActions.addContractAddress(data))

  }

  onSendCoin = () => {
    const { users } = this.props;
    let { getSavedAddress, } = users;

    const data = {
      from_address: getSavedAddress,
      to_address: this.state.toAddress,
      send_token_amount: this.state.amount,
    };

    console.log('datadatadatadata', data);
    this.props.dispatch(userActions.sendCoin(data))
  }
  copyTxIdToClipboard = (getSavedAddress) => {
    const tempInput = document.createElement('input');
    tempInput.value = getSavedAddress;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: getSavedAddress });
    this.props.dispatch(alertActions.success("Copied!"));
  };
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  render() {

    let { users } = this.props;
    let { getAddressList } = users;
    const { copiedTxId } = this.state;
    let walletAdd = this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : null
    console.log('getWalletListDatagetWalletListData', walletAdd);
    return (


      <div className="w-[350px] md:w-[600px] mx-auto   z-50 h-screen  flex justify-center items-center">
        <div className="bg-[#1C2745] shadow-md border rounded-[24px] p-6">
          <div className='flex items-center justify-end'>
            <button className="text-white inline-flex p-2 font-bold rounded-full hover:bg-[#9CA3AF]" type="button">
              <MdClose size={20} onClick={() => this.onClickMenu('/app/dashboard')} />
            </button>
          </div>
          <h3 className='flex text-white items-center justify-center font-bold text-xl'>Add contract address</h3>
          <div className="space-y-4 px-4 py-10">


            <div className='flex justify-between items-center border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   p-2.5  '>
              <span className='lg:text-base text-[10px]'>{walletAdd}</span>
              <button
                onClick={() => this.copyTxIdToClipboard(walletAdd)}
                className="ml-2 text-center text-blue-500 cursor-pointer"
                disabled={copiedTxId === walletAdd}
              >
                {copiedTxId === walletAdd ? <LuCopyCheck className='w-7 h-7 text-[#007F00]' /> : <GoCopy className='w-7 h-7' />}
              </button>
            </div>
            <div>
              <input className=" border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   block w-full p-2.5    dark:text-white "
                type="text" value={this.state.cAddress} onChange={(e) => { this.setState({ cAddress: e.target.value }) }}
                name="Address" placeholder="Enter the contract address">
              </input>
            </div>

            <div className="">
              <button onClick={() => this.contact_address()} className=' w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline'>
                Submit
              </button>
            </div>

          </div>

        </div>
      </div>


    )
  }
}
function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user, wallet } = overview ? overview : {};
  return {
    users,
    setting,
    user,
    dashboard,
    wallet
  };
}
export default connect(mapStateToProps)(AddContract);

