import React from "react";
import { Route, Switch, withRouter, } from "react-router-dom";
import Logout from "../../pages/Logout/Logout";
import Wallet from "../../pages/Wallet/Wallet";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import Referral from "../../pages/referral";
import Security from "../../pages/security";
import Transactions from "../../pages/transactions";
import CommingSoon from "../commingSoon";
import Support from "../../pages/support";
// import News from "../../pages/news";
import News from "../../pages/news/News";
import Contactus from "../../pages/contactus";
import Sidebar from "../Sidebar";
import referalTable from "../../pages/dashboard/components/referalTable/referalTable";
import changePassword from "../../pages/changePassword/changePassword";
import SwapCoin from "../../pages/SwapCoin/SwapCoin";
import KYC from "../../pages/KYC/KYC";
import buyCoin from "../../pages/buyCoin/buyCoin";
import Send from "../../pages/Send/Send";
import Payment from "../../pages/Payment/Payment";
import AddContract from "../../pages/AddContract/AddContract";
import notification from "../../pages/notification/notification";
import Video from "../../pages/Video/Video";
import Videos from "../../pages/Video/Videos";

function Layout(props) {
  

  const currentPath = props.location.pathname.split('/');
  const dashboard = currentPath[2];

  return (
    <div >
      <>
        <div className="overflow-hidden bg-[#1C2745] ">
          <div className="bg-[#1C2745] ">
            <div className="flex flex-col justify-center h-screen">
              <div className="flex h-screen overflow-hidden" >
                <Sidebar />
                <div className="flex flex-col flex-1 w-full overflow-hidden overflow-y-auto">
                  <Header history={props.history} currentPath={dashboard} name="" />
                  <Switch>
                    <Route path="/app/referalTable/:refId?" component={withRouter(referalTable)} />
                    <Route path="/app/changePassword" component={withRouter(changePassword)} />
                    <Route path="/contactus" component={withRouter(Contactus)} />
                    <Route path="/app/dashboard/:walletName?/:address?" component={withRouter(Dashboard)} />
                    <Route path="/app/wallet" component={withRouter(Wallet)} />
                    <Route path="/app/buyCoin" component={withRouter(buyCoin)} />
                    <Route path="/app/profile" component={withRouter(Profile)} />
                    <Route path="/app/referral" component={withRouter(Referral)} />
                    <Route path="/app/security" component={withRouter(Security)} />
                    <Route path="/app/Send/:address?/:contractAddr?" component={withRouter(Send)} />
                    <Route path="/app/support" component={withRouter(Support)} />
                    <Route path="/app/news" component={withRouter(News)} />
                    <Route path="/app/video" component={withRouter(Video)} />
                    <Route path="/app/videos/:id" component={withRouter(Videos)} />
                    <Route path="/app/swapCoin" component={withRouter(SwapCoin)} />
                    <Route path="/app/KYC" component={withRouter(KYC)} />
                    <Route path="/app/transactions" component={withRouter(Transactions)} />
                    <Route path="/app/commingSoon" component={withRouter(CommingSoon)} />
                    <Route path="/app/Payment/:address?" component={withRouter(Payment)} />
                    <Route path="/app/AddContract/:address?" component={withRouter(AddContract)} />
                    <Route path="/app/notification" component={withRouter(notification)} />
                    <Route path="/app/logout" component={withRouter(Logout)} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);


