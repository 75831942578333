import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { MdArrowOutward } from "react-icons/md";
import { userActions } from '../../_actions';
import { Helmet } from 'react-helmet';
class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      offsetOverflow: 0,
      sizeOverflow: 10,
      keyWordOverflow: "",
      pageNoOverflow: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        txType: null,
        from: Date.now(),
        to: Date.now(),
      },
      sendData: null,
      receiveData: null,
      showAllData: 0,
      activeButton: 'All'
    }
  }

  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
    }
    this.props.dispatch(userActions.getTx(data));
    this.props.dispatch(userActions.getProfile(data));
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": this.state.txType,
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        "from": this.state.formField.from,
        "to": this.state.formField.to,
      }
      this.props.dispatch(userActions.getTx(reqData, this.props));
    }
  }

  // Other methods remain unchanged

  handleSendData = () => {
    const { txList, getProfile } = this.props.users;
    const sendData = txList && txList.length > 0 ? txList.filter(ele => ele.from.toLowerCase() === getProfile.wallet.address) : null;
    this.setState({ sendData, receiveData: null, showAllData: false });
  }

  handleReceiveData = () => {
    const { txList, getProfile } = this.props.users;
    const receiveData = txList && txList.length > 0 ? txList.filter(ele => ele.to.toLowerCase() === getProfile.wallet.address) : null;
    this.setState({ receiveData, sendData: null, showAllData: false });
  }

  handleAllData = () => {
    this.setState({ showAllData: 0 });
    this.setState({ activeButton: 'All' });
  }
  handleAllData1 = () => {
    this.setState({ showAllData: 1 });
    this.setState({ activeButton: 'Send' });

  }
  handleAllData2 = () => {
    this.setState({ showAllData: 2 });
    this.setState({ activeButton: 'Receive' });

  }


  render() {
    // const { sendData, receiveData, showAllData } = this.state;
    const { users } = this.props;
    const { txList, getProfile } = users;
    const sendData = txList && txList.length > 0 ? txList.filter(ele => ele.from.toLowerCase() === getProfile.wallet.address.toLowerCase()) : null;
    const receiveData = txList && txList.length > 0 ? txList.filter(ele => ele.to.toLowerCase() === getProfile.wallet.address.toLowerCase()) : null;
    // console.log("overflowTxList_____________RENDER_______", txList);
    // console.log("overflowTxList_____________RENDER_______", getProfile.wallet.address);
    const { activeButton } = this.state;
    return (
      <>

        <Helmet>
          <title>VuneCoin - wallet</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <section className='overflow-hidden overflow-y-auto lg:pb-0 pb-20'>
          <div className='w-11/12 mx-auto '>
            <h1 className='text-[26px] font-[700] text-[#FFFFFF] '>Transaction list</h1>
          </div>

          <div className='w-11/12 mx-auto py-10'>
            <div className='border border-[#5B4DCA] rounded-[14px] p-6 space-y-4'>
              {/* <div className='flex justify-between items-center rounded-full border border-[#606060] lg:px-14 px-6 py-4 overflow-x-auto space-x-2'>
                <button type="button" className='lg:px-24 px-6 py-2 rounded-full border border-[#606060] text-white text-[16px] font-[400]' onClick={this.handleAllData}>All</button>
                <button type="button" className='lg:px-24 px-6 py-2 rounded-full border border-[#606060] text-white text-[16px] font-[400]' onClick={this.handleAllData1}>Send</button>
                <button type="button" className='lg:px-24 px-6 py-2 rounded-full border border-[#606060] text-white text-[16px] font-[400]' onClick={this.handleAllData2}>Receive</button>
              </div> */}


              {/* {(sendData || receiveData || showAllData) && (
                <>
                  <h1 className='text-[26px] font-[700] text-[#FFFFFF] border-b border-[#D9D9D975]'>{sendData ? 'Send Data' : receiveData ? 'Receive Data' : 'All Data'}</h1>
                  {(sendData || receiveData || showAllData) && (
                    <>
                      {(showAllData === 1) && sendData && sendData.map((element, index) => (
                        <div className='md:flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                          <div className='flex flex-col space-y-1 py-4'>
                            <p className='text-[#FFFFFFA6] text-[11px] font-[600]' >{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                            <div className='flex items-center space-x-2'>
                              <div className='bg-[#4F91F5] rounded-full w-10 h-10 flex justify-center items-center'><MdArrowOutward size={28} color='#fff' /></div>
                              <div>
                                <p className='text-[#FFFFFF] text-[16px] font-[600]'>Leytia</p>
                                <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.to}>To : {element && element.to.substring(0, 20) ? element.to.substring(0, 20) + "..." : element.to}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                            <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? Number(element.valueEth) * 2 : "-"}</p>
                          </div>
                        </div>
                      ))}
                      {(  showAllData === 2) && receiveData && receiveData.map((element, index) => (
                        <div className='md:flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                          <div className='flex flex-col space-y-1 py-4'>
                            <p className='text-[#FFFFFFA6] text-[11px] font-[600]' >{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                            <div className='flex items-center space-x-2'>
                              <div className='bg-[#4F91F5] rounded-full w-10 h-10 flex justify-center items-center'><MdArrowOutward size={28} color='#fff' /></div>
                              <div>
                                <p className='text-[#FFFFFF] text-[16px] font-[600]'>Leytia</p>
                                <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.to}>To : {element && element.to.substring(0, 20) ? element.to.substring(0, 20) + "..." : element.to}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                            <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? Number(element.valueEth) * 2 : "-"}</p>
                          </div>
                        </div>
                      ))}
                      {txList && txList.map((element, index) => (
                        <div className='md:flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                          <div className='flex flex-col space-y-1 py-4'>
                            <p className='text-[#FFFFFFA6] text-[11px] font-[600]' >{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                            <div className='flex items-center space-x-2'>
                              <div className='bg-[#4F91F5] rounded-full w-10 h-10 flex justify-center items-center'><MdArrowOutward size={28} color='#fff' /></div>
                              <div>
                                <p className='text-[#FFFFFF] text-[16px] font-[600]'>Leytia</p>
                                <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.to}>To : {element && element.to.substring(0, 20) ? element.to.substring(0, 20) + "..." : element.to}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                            <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? Number(element.valueEth) * 2 : "-"}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )} */}

              <div className='flex justify-between items-center rounded-full border border-[#606060] lg:px-14 px-6 py-4 overflow-x-auto space-x-2'>
                <button
                  type="button"
                  className={`lg:px-24 px-6 py-2 rounded-full border border-[#606060] text-white text-[16px] font-[400] ${activeButton === 'All' ? 'relative' : ''}`}
                  onClick={this.handleAllData}
                >
                  All
                  {activeButton === 'All' && <span className="absolute top-4 ml-2 inset-y-0 w-2 h-2 bg-[#38EF7D] rounded-full"></span>}
                </button>
                <button
                  type="button"
                  className={`lg:px-24 px-6 py-2 rounded-full border border-[#606060] text-white text-[16px] font-[400] ${activeButton === 'Send' ? 'relative' : ''}`}
                  onClick={this.handleAllData1}
                >
                  Send
                  {activeButton === 'Send' && <span className="absolute top-4 ml-2 inset-y-0 w-2 h-2 bg-[#38EF7D] rounded-full"></span>}
                </button>
                <button
                  type="button"
                  className={`lg:px-24 px-6 py-2 rounded-full border border-[#606060] text-white text-[16px] font-[400] ${activeButton === 'Receive' ? 'relative' : ''}`}
                  onClick={this.handleAllData2}
                >
                  Receive
                  {activeButton === 'Receive' && <span className="absolute top-4 ml-2 inset-y-0 w-2 h-2 bg-[#38EF7D] rounded-full"></span>}
                </button>
              </div>


              {this.state.showAllData && this.state.showAllData === 1 ?
                (sendData && sendData.length > 0 ?
                  sendData.map((element, index) => (
                    <div className='flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                      <div className='flex flex-col space-y-1 py-4'>
                        <p className='text-[#FFFFFFA6] text-[11px] font-[600]'>{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp * 1000 : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                        <a href={`https://explorer.vunecoin.com/tx/${element.hash}`} target='_blank' rel="noreferrer">
                          <div className='flex items-center space-x-2'>
                            <div className='bg-[#000] rounded-full w-12 h-12 flex justify-center items-center'><img src="/img/leytia-send-icon.png" alt="" /></div>
                            <div>
                              <p className='text-[#FFFFFF] text-[16px] font-[600]'>Vunecoin</p>
                              <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.to}>To : {element && element.to.substring(0, 20) ? element.to.substring(0, 20) + "..." : element.to}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div>
                        <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                        <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? (Number(element.valueEth) * 2).toFixed(16) : "-"}</p>
                      </div>
                    </div>
                  ))
                  :
                  <p className='text-white'>No data found.</p>
                )
                :
                this.state.showAllData && this.state.showAllData === 2 ?
                  (receiveData && receiveData.length > 0 ?
                    receiveData.map((element, index) => (
                      <div className='flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                        <div className='flex flex-col space-y-1 py-4'>
                          <p className='text-[#FFFFFFA6] text-[11px] font-[600]'>{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp * 1000 : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                          <a href={`https://explorer.vunecoin.com/tx/${element.hash}`} target='_blank' rel="noreferrer">
                            <div className='flex items-center space-x-2'>
                              <div className='bg-[#000] rounded-full w-12 h-12 flex justify-center items-center'><img src="/img/leytia-receive-icon.png" alt="receive" /></div>
                              <div>
                                <p className='text-[#FFFFFF] text-[16px] font-[600]'>Vunecoin</p>
                                <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.from}>from : {element && element.from.substring(0, 20) ? element.from.substring(0, 20) + "..." : element.from}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div>
                          <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                          <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? (Number(element.valueEth) * 2).toString() : "-"}</p>
                        </div>
                      </div>
                    ))
                    :
                    <p className='text-white'>No data found.</p>
                  )
                  :
                  (txList && txList.length > 0 ?
                    txList.map((element, index) => {
                      if (sendData && sendData.some(e => e?.from?.toString() === element?.from.toString())) {
                        return (
                          <div className='flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                            <div className='flex flex-col space-y-1 py-4'>
                              <p className='text-[#FFFFFFA6] text-[11px] font-[600]'>{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp * 1000 : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                              <a href={`https://explorer.vunecoin.com/tx/${element.hash}`} target='_blank' rel="noreferrer">
                                <div className='flex items-center space-x-2'>
                                  <div className='bg-[#000] rounded-full w-12 h-12 flex justify-center items-center'><img src="/img/leytia-send-icon.png" alt="receive" /></div>
                                  <div>
                                    <p className='text-[#FFFFFF] text-[16px] font-[600]'>Vunecoin</p>
                                    <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.to}>To : {element && element.to.substring(0, 20) ? element.to.substring(0, 20) + "..." : element.to}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div>
                              <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                              <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? (Number(element.valueEth) * 2).toFixed(16) : "-"}</p>
                            </div>
                          </div>
                        )
                      }
                      if (receiveData && receiveData.some(e => e?.from?.toString() === element?.from.toString())) {
                        return (
                          <div className='flex justify-between items-center border-b border-[#D9D9D975]' key={index}>
                            <div className='flex flex-col space-y-1 py-4'>
                              <p className='text-[#FFFFFFA6] text-[11px] font-[600]'>{moment(new Date(parseInt(element && element.timeStamp ? element.timeStamp * 1000 : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</p>
                              <a href={`https://explorer.vunecoin.com/tx/${element.hash}`} target='_blank' rel="noreferrer">
                                <div className='flex items-center space-x-2'>
                                  <div className='bg-[#4F91F5] rounded-full w-10 h-10 flex justify-center items-center'>
                                    <div className='bg-[#000] rounded-full w-12 h-12 flex justify-center items-center'><img src="/img/leytia-receive-icon.png" alt="receive" /></div>
                                  </div>
                                  <div>
                                    <p className='text-[#FFFFFF] text-[16px] font-[600]'>Vunecoin</p>
                                    <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={element.to}>From : {element && element.to.substring(0, 20) ? element.to.substring(0, 20) + "..." : element.to}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div>
                              <p className='text-[16px] font-[600] text-[#ffffff]'>{element && element.valueEth ? element.valueEth : "-"}</p>
                              <p className='text-[11px] font-[400] text-[#FFFFFFA8] text-right'>{element && element.valueEth ? Number(element.valueEth) * 2 : "-"}</p>
                            </div>
                          </div>
                        )

                      }
                    })
                    :
                    <p className='text-white'>No data found.</p>
                  )
              }
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}

export default connect(mapStateToProps)(Transaction);
