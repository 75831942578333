import React, { Component } from 'react';
import { dashboardActions, userActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import "jspdf-autotable";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { BsArrowUpCircle, BsArrowDownCircle } from "react-icons/bs";
import { confirmAlert } from 'react-confirm-alert';
import TetherModal from './components/TetherModal/TetherModal';
import ReceiveModal from './components/ReceiveModal/ReceiveModal';
import { Helmet } from 'react-helmet';


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldslogin: {},
      errorslogin: {},
      option: {},
      showLeaderSection: false,
      activeTab: 1,
      isPackageListOpen: true,
      isHistoryOpen: false,
      isBioPreservation: false,
      isCertificates: false,
      isHovered: false,
      isNetwork: false,
      isTether: false,
      fieldTicketId: false,
      transactionModal: false,
      openText: false,
      clickedOutside: 0,
      clickedOutBanking: false,
      isPayment: false,
      isReceive: false,
      addressName: '',
      toAddress: '',
      amount: '',
      coin: { symbol: 'GCN' }, // Replace with your initial coin state
      loader: false,
      coinSelect: '',
      cAddress: '',
      amount1: '',
      walletName: ''
    }
  }

  componentDidMount() {
    let addresss = this.props.match && this.props.match.params && this.props.match.params.address ? this.props.match.params.address : null;
    let tempsData = {
      address: addresss
    }

    this.props.dispatch(userActions.getAddressList())
    this.props.dispatch(userActions.getWalletList(tempsData))
    this.props.dispatch(userActions.getUserWalletToBuy(tempsData))
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps) {

    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {}
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  handleBuyPackage = (data) => {
    let datatemp = {
      packageId: data.id,
      amount: data.price
    }
    confirmAlert({
      title: 'Confirm to buy package?',
      p: `Are you sure to spend ${data.price} $?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.dispatch(dashboardActions.buyPackage(datatemp))
            // this.props.dispatch(userActions.getProfileDataPi())
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleOpenPackageList = () => {
    this.setState({ isPackageListOpen: true });
  };

  handleClosePackageList = () => {
    this.setState({ isPackageListOpen: false });
  };

  handleOpenNetwork = (ele) => {
    console.log('ele______________________________', ele);
    const { users } = this.props;
    let { getAddressList } = users;
    const add = this.props.match && this.props.match.params && this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : ''
    const contractAddr = ele && ele.cAddress ? ele.cAddress : null
    this.props.history.push('/app/Send' + "/" + add + "/" + contractAddr)

    this.props.dispatch(userActions.savedname(ele))


  };

  handleCloseNetwork = () => {
    this.setState({ isNetwork: false });
  };

  handleOpenTether = () => {
    this.setState({ isTether: true })
  };

  handleCloseTether = () => {
    this.setState({ isTether: false })
  }

  handleOpenPayment = () => {
    const { users } = this.props;
    let { getAddressList } = users;
    const add = this.props.match && this.props.match.params && this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : ''
    this.props.history.push('/app/Payment' + "/" + add)

  };

  handleClosePayment = () => {
    this.setState({ isPayment: false })
  }
  handlePrimaryWalletOpen = () => {
    this.setState({ fieldTicketId: true })
  }
  handlePrimaryWalletClose = () => {
    this.setState({ fieldTicketId: false })
  }

  handleTransactionOpen = () => {

    const { cAddress } = this.state;
    const { users } = this.props;
    let { getAddressList } = users;
    const add = this.props.match && this.props.match.params && this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : ''
    this.props.history.push("/app/AddContract" + '/' + add)
  }

  handleTransactionClose = () => {
    this.setState({ transactionModal: false })
  }
  handleRes = () => {
    this.setState({ openText: !this.state.openText })
  }
  handleResOutBanking = () => {
    this.setState({ clickedOutBanking: !this.state.clickedOutBanking })
  }

  handleResOut = (data) => {
    console.log('data_____________________', data);
    this.setState({ clickedOutside: data })
  }

  handleOpenReceive = () => {
    this.setState({ isReceive: true })
  };

  handleCloseReceive = () => {
    this.setState({ isReceive: false })
  }

  onCreateWallet = () => {
    if (!this.state.addressName) {
      alertActions.error('Wallet name is required');
      return;
    }
    let data = {
      walletName: this.state.addressName
    }

    this.props.dispatch(userActions.createWallet(data))

  }

  Saveaddresss = (temp) => {

    this.setState({ walletName: temp && temp.walletName })
    this.handlePrimaryWalletClose()
    const { users } = this.props;
    let { getAddressList } = users;
    let addresss = this.props.match && this.props.match.params && this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : ''

    let tempsData = {
      address: addresss
    }
    this.props.dispatch(userActions.getAddressList(tempsData))

    if (temp && temp.walletName || temp && temp.walletName == null) {
      let tempWallet = temp && temp.walletName ? `/app/dashboard/${temp.walletName}/${temp.address}` : `/app/dashboard/Primary/${temp.address}`
      this.props.history.push(tempWallet)
    }
  }
  onSendCoin = () => {
    const { users } = this.props;
    let { getSavedAddress, } = users;

    const data = {
      from_address: getSavedAddress,
      to_address: this.state.toAddress,
      send_token_amount: this.state.amount,
    };

    console.log('datadatadatadata', data);
    this.props.dispatch(userActions.sendCoin(data))
  }

  send_wallet = () => {
    let gcn_balance = Number(this.state.amount1)
    let data = {
      "address": this.state.coinSelect,
      "amount": gcn_balance
    }
    this.props.dispatch(userActions.buyGCN(data))
  }

  contact_address = () => {
    const { cAddress } = this.state;
    const { users } = this.props;
    let { getAddressList } = users;

    let data = {
      "cAddress": cAddress,
      "address": this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : null
    }
    this.props.dispatch(userActions.addContractAddress(data))

  }

  handleCoinChange = (event) => {
    this.setState({ coinSelect: event.target.value });
  };

  copyTxIdToClipboard = (getSavedAddress) => {
    const tempInput = document.createElement('input');
    tempInput.value = getSavedAddress;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: getSavedAddress });
    this.props.dispatch(alertActions.success("Copied!"));
  };


  render() {

    let { users } = this.props;
    let { getSavedAddress, getAddressList, getWalletListData, getUserWalletToBuy } = users;

    let walletAdd = this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : null

    console.log('getWalletListData', getUserWalletToBuy);

    return (

      <>
        <Helmet>
          <title>VuneCoin - dashboard</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>


        <section className='overflow-hidden overflow-y-auto  lg:pb-0 pb-20'>
          <div className='w-11/12 mx-auto py-10'>
            <div className='border border-[#5B4DCA] rounded-[14px] p-6'>
              <h1 className='text-[26px] font-[700] text-[#FFFFFF] '>My wallet</h1>
              <div className='md:flex justify-between items-center'>
                <div className='  flex flex-none items-center md:space-x-4 space-x-4  justify-center py-6 px-3'>
                  <div onClick={() => this.handleOpenReceive()} className='flex cursor-pointer rounded-full all-btn-color px-8 py-3 space-x-2'>
                    <span className='flex justify-center items-center'><BsArrowDownCircle size={22} className='font-bold text-white' /></span>
                    <span className=" text-base font-bold flex justify-center items-center text-white "> Receive</span>
                  </div>
                  <div onClick={() => this.handleOpenNetwork(getWalletListData && getWalletListData[0])} className='flex cursor-pointer rounded-full all-btn-color px-8 py-3 space-x-2'>
                    <span className='flex justify-center items-center'><BsArrowUpCircle size={22} className='font-bold text-white' /></span>
                    <span className=" text-base font-bold flex justify-center items-center text-white "> Send</span>
                  </div>
                  {/* <div title='contact address' onClick={() => this.handleTransactionOpen()} className='flex justify-end items-center cursor-pointer'>
                    <MdOutlineAddCircleOutline className=' text-white font-bold' size={42} />
                  </div> */}
                </div>
                <div className='bg-[#5763D8] flex justify-between p-4 space-x-6 rounded-[12px]'>
                  <div>
                    <p className='xl:text-[22px] text-[14px] font-[700] text-[#FFFFFF]'>Available balance</p>
                    <p className='text-[14px] font-[400] text-[#FFFFFFB2]'>{getUserWalletToBuy && getUserWalletToBuy.gcnAmount ? getUserWalletToBuy.gcnAmount : '0.00'} </p>
                  </div>
                  <div className='flex justify-end items-end pt-10'>
                    <img src="/img/leytia-coin-logo-wallet.png" alt="" className='w-14 h-14 bg-[#fff] p-2 rounded-full' />
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className='w-11/12 mx-auto py-10'>
            <div className='border border-[#5B4DCA] rounded-[14px] p-6'>
              <h1 className='text-[26px] font-[700] text-[#FFFFFF] border-b border-[#D9D9D975]'>Recent activity</h1>
              <>
                {
                  getWalletListData && getWalletListData.length > 0 ? getWalletListData.map((ele) => (
                    <div className='flex justify-between items-center border-b border-[#D9D9D975] cursor-pointer' onClick={() => this.handleOpenNetwork(ele)}>
                      <div className='flex flex-col space-y-1 py-4'>
                        <div className='flex items-center space-x-2'>
                          <div className='bg-[#4F91F5] text-white rounded-full w-14 h-14 flex justify-center items-center'> <img src="/img/leytia-coin-logo-wallet.png" alt="" className='w-14 h-14 bg-[#fff] p-2 rounded-full' /></div>
                          <div>
                            <p className='text-[#FFFFFF] text-[16px] font-[600]'> {ele.name === 'VuneCoin' ? 'VuneCoin' : ele.name}</p>
                            <p className='text-[#FFFFFFA6] text-[11px] font-[400]' title={ele.address}>{ele && ele.address.substring(0, 20) ? ele.address.substring(0, 20) + "..." : ele.address}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className='text-[16px] font-[600] text-[#ffffff]'>{ele.balance}</p>
                      </div>
                    </div>
                  ))
                    : null
                }
              </>

            </div>
          </div>
        </section>

        <TetherModal
          handleOpenTether={this.state.isTether}
          handleCloseTether={this.handleCloseTether}
        />
        <ReceiveModal
          handleCloseReceive={this.handleCloseReceive}
          handleOpenPayment={this.state.isReceive}
          handleqrcode={getSavedAddress}
          walletAdd={walletAdd}
          props={this.props}
        />
      </>

    );
  }

}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user, wallet } = overview ? overview : {};
  return {
    users,
    setting,
    user,
    dashboard,
    wallet
  };
}
export default connect(mapStateToProps)(Dashboard);