import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {

      fieldsUser: {},
      errorslogin: {},
      optUpdatePassword: false,
      showPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,

    }
  }

  static getDerivedStateFromProps(nextProps) {
    console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        optUpdatePassword: nextProps.users.otpSentUpdatePass ? nextProps.users.otpSentUpdatePass : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.getUserDetails());
  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = this.state.errorslogin;
    fieldsUser[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorslogin });
  }


  updatePasswordSubmit = () => {

    if (this.handleValidationUserInfo()) {
      let data = {
        currentpassword: this.state.fieldsUser.currentpassword,
        newpassword: this.state.fieldsUser.newpassword,
        confirmnewpassword: this.state.fieldsUser.confirmnewpassword

      }
      // console.log("updatePasswordSubmit___updatePasswordSubmit:::", data);
      this.props.dispatch(userActions.updatePassword(data, this.props));
    }
  }

  updateEmailSubmit = () => {

    if (this.handleValidationEmail()) {
      let data = {
        email: this.state.fieldsUser.email
      }
      // console.log("changeEmail___changeEmail:::", data);
      this.props.dispatch(userActions.changeEmail(data, this.props));
    }
  }


  handleValidationEmail = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //email
    if (!fieldsUser["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Plz enter Email !";
    }
    // console.log("errorsUsererrorsUsererrorsUser", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationUserInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //currentpassword
    if (!fieldsUser["currentpassword"]) {
      formIsValid = false;
      errorslogin["currentpassword"] = "Enter current password!";
    }

    //newpassword
    // if (!fieldsUser["newpassword"]) {
    //   formIsValid = false;
    //   errorslogin["newpassword"] = "Invalid new password!";
    // }


    if (!fieldsUser["newpassword"] || !fieldsUser["newpassword"].match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,15})/)) {
      formIsValid = false;
      errorslogin["newpassword"] = "Password should be between 8 to 15 characters and should contain at least one uppercase, one lowercase, one number, and one special character.";
    }

    // confirmnewpassword validation
    if (!fieldsUser["confirmnewpassword"]) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Enter confirm new password!";
    } else if (fieldsUser["confirmnewpassword"] !== fieldsUser["newpassword"]) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "New password and confirm new password must be same.";
    }

    // console.log("errorsUsererrorsUsererrorsUser", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationUserInfo2 = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    // if (!fieldsUser["newpassword"] || !fieldsUser["newpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")) {
    //   formIsValid = false;
    //   errorslogin["newpassword"] = "Invalid Password!";
    // }


    if (!fieldsUser["newpassword"] || !fieldsUser["newpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["newpassword"] = "Password should be between 8 to 15 characters and should contain atleast one uppercase, one lowercase ,one number and one special character.";
    }


    //confirmnewpassword
    if (!fieldsUser["confirmnewpassword"] || !fieldsUser["confirmnewpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Invalid confirmnewpassword!";
    }

    //currentpassword
    if (!fieldsUser["currentpassword"] || fieldsUser["currentpassword"] === "") {
      formIsValid = false;
      errorslogin["currentpassword"] = "Cannot Be Empty";
    }


    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };

  toggleNewPasswordVisibility = () => {
    this.setState(prevState => ({
      showNewPassword: !prevState.showNewPassword
    }));
  };

  toggleConfirmNewPasswordVisibility = () => {
    this.setState(prevState => ({
      showConfirmNewPassword: !prevState.showConfirmNewPassword
    }));
  };

  render() {

    return (
      <>
         <Helmet>
          <title>VuneCoin - changePassword</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <div className='overflow-hidden overflow-y-auto'>
          <div className="py-20 mx-auto w-11/12">

            <div className='space-y-6'>
              <h1 className='text-[26px] font-[700] text-[#ffffff]'>Update password</h1>
              <div className="w-full mx-auto border border-[#5B4DCA] rounded-[14px] shadow-lg p-10">
                <div className='w-full grid xl:grid-cols-2 xl:gap-8 gap-6'>
                  <div className="w-full space-y-2 relative">
                    <label className="block text-[18px] font-[400] text-[#ffffff]" htmlFor="grid-first-name">Current password</label>
                    <div className='relative'>
                      <input
                        className="block w-full px-4 py-3 leading-tight text-[#fff] text-base font-normal bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none"
                        name="currentpassword"
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder='Current password'
                        value={this.state.fieldsUser && this.state.fieldsUser["currentpassword"] ? this.state.fieldsUser["currentpassword"] : ''}
                        onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }}
                      />
                      <button
                        className="absolute inset-y-0 top-1 right-0 pr-3 flex items-center focus:outline-none"
                        onClick={this.togglePasswordVisibility}
                      >
                        {this.state.showPassword ?
                          <FaEye className="text-gray-400 cursor-pointer" />
                          :
                          <FaEyeSlash className="text-gray-400 cursor-pointer" />
                        }
                      </button>
                    </div>
                    {this.state.errorslogin["currentpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["currentpassword"]}
                      </div>
                      : null}
                  </div>
                  {/* <div className="w-full space-y-2 relative">
                    <label className="block text-[18px] font-[400] text-[#ffffff]" htmlFor="grid-first-name">New Password</label>
                    <input
                      className="block w-full px-4 py-3 leading-tight text-[#fff] text-base font-normal bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none"
                      name="newpassword"
                      type={this.state.showNewPassword ? 'text' : 'password'}
                      placeholder='New Password'
                      value={this.state.fieldsUser && this.state.fieldsUser["newpassword"] ? this.state.fieldsUser["newpassword"] : ''}
                      onChange={this.inputChange}
                    />
                    <button
                      className="absolute inset-y-0 top-7 right-0 pr-3 flex items-center focus:outline-none"
                      onClick={this.toggleNewPasswordVisibility}
                    >
                      {this.state.showNewPassword ?
                        <AiFillEyeInvisible size={24} className='text-gray-700' />
                        :
                        <AiFillEye size={24} className='text-gray-700' />
                      }
                    </button>
                    {this.state.errorslogin["newpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["newpassword"]}
                      </div>
                      : null}
                  </div> */}

                  <div className="w-full space-y-2 relative">
                    <label className="block text-[18px] font-[400] text-[#ffffff]" htmlFor="grid-first-name">New password</label>
                    <div className='relative'>
                      <input
                        className=" block w-full px-4 py-3 leading-tight text-[#fff] text-base font-normal bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none"
                        name="newpassword"
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        placeholder='New password'
                        value={this.state.fieldsUser && this.state.fieldsUser["newpassword"] ? this.state.fieldsUser["newpassword"] : ''}
                        onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }}
                      />
                      <button
                        className="absolute inset-y-0 top-1 right-0 pr-3 flex items-center focus:outline-none"
                        onClick={this.toggleNewPasswordVisibility}
                      >
                        {this.state.showNewPassword ?
                          <FaEye className="text-gray-400 cursor-pointer" />
                          :
                          <FaEyeSlash className="text-gray-400 cursor-pointer" />
                        }
                      </button>
                    </div>
                    {this.state.errorslogin["newpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["newpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full space-y-2 relative">
                    <label className="block text-[18px] font-[400] text-[#ffffff]" htmlFor="grid-first-name">Confirm new password</label>
                    <div className='relative'>
                      <input
                        className="block w-full px-4 py-3 leading-tight text-[#fff] text-base font-normal bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none"
                        name="confirmnewpassword"
                        type={this.state.showConfirmNewPassword ? 'text' : 'password'}
                        placeholder='Confirm new password'
                        value={this.state.fieldsUser && this.state.fieldsUser["confirmnewpassword"] ? this.state.fieldsUser["confirmnewpassword"] : ''}
                        onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }}
                      />
                      <button
                        className="absolute inset-y-0 top-1 right-0 pr-3 flex items-center focus:outline-none"
                        onClick={this.toggleConfirmNewPasswordVisibility}
                      >
                        {this.state.showConfirmNewPassword ?
                          <FaEye className="text-gray-400 cursor-pointer" />
                          :
                          <FaEyeSlash className="text-gray-400 cursor-pointer" />
                        }
                      </button>
                    </div>
                    {this.state.errorslogin["confirmnewpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["confirmnewpassword"]}
                      </div>
                      : null}
                  </div>

                </div>
                <div className="block w-full py-6 rounded-md shadow-sm">
                  <button className="flex justify-center w-full px-3 py-3 mx-auto text-[22px] text-white uppercase transition duration-150 ease-in-out   rounded-md font-[700] all-btn-color focus:outline-none" type="button" onClick={this.updatePasswordSubmit}>Update</button>
                </div>
              </div>
            </div>

          </div>


        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user
  };
}
export default connect(mapStateToProps)(ChangePassword);
