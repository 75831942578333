import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import "jspdf-autotable";
import { Helmet } from 'react-helmet';
import { AiOutlineLike, AiOutlineShareAlt } from 'react-icons/ai';
import { FaRegComment } from 'react-icons/fa';
import moment from 'moment';
import { userService } from '../../_services';
import { userActions } from '../../_actions';
import { timeDifference } from '../../_helpers';
class Video extends Component {
  constructor(props) {
    super(props);
    this.listInnerRef = React.createRef();
    this.videoRef = React.createRef();
    this.state = {
      offset: 0,
      size: 5,
      page: 1,
      commentSize: 10,
      errorsticket: {},
      fieldsticket: {},
      ticketTitle: "",
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      isResolved: null,
      selectedValue: "",
      selectedVideo: null,
      isLiked: false,
      onlyOnce: true,
      videoData: null,
      totalLike: 0,
      comment: "",
      isPiPActive: false,
      lastPlayedTime: 0,
      showComment: false,
      responseCommentData: [],
      responseTotalComment: 0,
      commentPageNo: 1
    }
  }


  fetchDataComment = (id) => {
    const commentData = {
      "videoId": id,
      "keyWord": "",
      "pageNo": this.state.commentPageNo,
      "size": this.state.commentSize,
    }
    userActions.getCommentsListOnVideoPromise(commentData).then((res) => {
      this.setState({ responseCommentData: [...this.state.responseCommentData, ...res.userData?.data?.list], responseTotalComment: res?.userData?.data?.total })
    })
  }

  fetchDataComment2 = (id) => {
    const commentData = {
      "videoId": id,
      "keyWord": "",
      "pageNo": this.state.commentPageNo,
      "size": this.state.commentSize,
    }
    userActions.getCommentsListOnVideoPromise(commentData).then((res) => {
      this.setState({ responseCommentData: [...res.userData?.data?.list], responseTotalComment: res?.userData?.data?.total })
    })
  }

  componentDidMount() {
    const { match } = this.props;
    const id = match?.params?.id;
    if (id) {
      this.fetchDataComment(id)
      this.fetchData(id)
    }

    let temp = {
      keyWord: "",
      pageNo: 1,
      size: 100
    };
    this.props.dispatch(userActions.getAllVideo(temp));
    window.scrollTo(0, 0);


    const videoElement = this.videoRef.current;

    this.enterPiPMode = async (videoElement) => {
      // Only allow entering PiP mode if there isn't an active PiP window
      if (!document.pictureInPictureElement) {
        try {
          await videoElement.requestPictureInPicture();
          this.setState({ isPiPActive: true });
        } catch (error) {
          console.error('Failed to enter PiP mode:', error);
        }
      }
    };

    this.exitPiPMode = async (videoElement) => {
      // Exit PiP mode if active
      if (document.pictureInPictureElement) {
        try {
          await document.exitPictureInPicture();
          this.setState({ isPiPActive: false });
          // Resume playback at the last played time when PiP closes
          videoElement.currentTime = this.state.lastPlayedTime;
          videoElement.play();
        } catch (error) {
          console.error('Failed to exit PiP mode:', error);
        }
      }
    };


    // Optional, or implement custom throttle
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Video is in view, stop PiP if active and resume on the large screen
            if (this.state.isPiPActive) {
              this.exitPiPMode(videoElement);
            }
          } else {
            // Video is out of view, trigger PiP mode
            this.enterPiPMode(videoElement);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );


    if (videoElement) {
      this.observer.observe(videoElement);
    }
  }


  componentWillUnmount() {
    const videoElement = this.videoRef.current;
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture().then(() => {
        this.setState({ isPiPActive: false });
        // Resume playback at the last played time when PiP closes
        videoElement.currentTime = this.state.lastPlayedTime;
        videoElement.play();
      }).catch(error => {
        console.log('Error exiting PiP:', error);
      });
    }

    if (videoElement) {
      this.observer.unobserve(videoElement);
    }

  }

  handleTimeUpdate = () => {
    // Track the current video time
    this.setState({ lastPlayedTime: this.videoRef.current.currentTime });
  };

  onScroll = () => {
    const { match } = this.props
    const id = match?.params?.id;
    if ((Math.ceil(this.state.responseTotalComment / this.state.commentSize) >= (this.state.commentPageNo))) {
      this.setState((prevState) => ({ commentPageNo: this.state.commentPageNo + 1 }),
        () => this.fetchDataComment(id)
      );
    }
  };



  componentDidUpdate(prevProps, prevState) {
    const videoElement = this.videoRef.current;
    if (prevState.selectedVideo !== this.state.selectedVideo && videoElement) {
      if (document.pictureInPictureElement) {
        videoElement.requestPictureInPicture().catch(error => {
          console.log('Error enabling PiP for new video:', error);
        });
      }
    }
  }



  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.onlyOnce && prevState?.videoData) {
      return {
        ...prevState,
        isLiked: prevState?.videoData?.isLiked,
        totalLike: prevState?.videoData?.totalLikes || 0,
        onlyOnce: false
      };
    } else {
      return null;
    }
  }


  fetchData = async (id) => {
    const response = await userService.getVideoDetailsByIdUser({ id });

    this.props.dispatch(userActions.shareViewUpdateVideo({ id, share: false }))
    this.setState({ videoData: response.userData.data });
  }


  handleThumbnailClick = (video) => {
    const videoElement = this.videoRef.current;

    if (document.pictureInPictureElement) {
      document.exitPictureInPicture().then(() => {
        this.setState({ selectedVideo: video }, () => {
          videoElement.requestPictureInPicture();
        });
      }).catch(error => {
        console.log('Error switching PiP video:', error);
      });
    } else {
      this.setState({ selectedVideo: video });
    }
  };


  handleClosePlayer = () => {
    this.setState({ selectedVideo: null });
  };

  onClickMenu = (url, video, list) => {
    this.props.history.push({
      pathname: url,
      search: "",
      state: { ...video, list }
    });
  };
  // Handle Like button click
  handleLike = (id) => {
    userService.addRemoveLikeVideo({ id }).then((res) => {
      this.setState((prevState) => ({
        isLiked: res.userData.message !== "Remove Like",
        totalLike: prevState?.totalLike + (res.userData.message === "Remove Like" ? -1 : 1)
      }));
    });
  };


  // Handle Share button click
  handleShare = (id) => {
    navigator.clipboard.writeText(window.location.href);
    this.props.dispatch(userActions.shareViewUpdateVideo({ id, share: true }))
    alert('Video URL copied to clipboard!');
  };

  // Handle Comment button click (scrolls to comment section)
  handleCommentFocus = () => {
    const commentSection = document.querySelector('.bg-gray-100');
    this.setState({ showComment: !this.state.showComment })
    if (commentSection) {
      commentSection.scrollIntoView({ behavior: 'smooth' });
    }
  };



  addComment = () => {
    const res = {
      comment: this.state.comment,
      videoId: this.state.videoData._id
    }
    this.props.dispatch(userActions.createComments(res))
    this.setState({ comment: "" })
    this.fetchDataComment2(this.state.videoData._id)
  }

  render() {
    let { users } = this.props;
    let { loading, vidodetailsdata } = users;
    return (
      <>
        <Helmet>
          <title>VuneCoin - Video</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...'
          />
        </div>
        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none ">
          <section className="w-11/12 mx-auto lg:pb-0 pb-10">
            {/* Video Section */}
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
              <div className="lg:col-span-2">
                <div className="relative pb-56.25">
                  <video
                    src={"https://wallet.vunecoin.com/images1/" + this.state.videoData?.videoFileName}
                    controls
                    autoPlay={false}
                    controlsList="nodownload"
                    onTimeUpdate={this.handleTimeUpdate}
                    className='w-full'
                    ref={this.videoRef}
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
                <h1 className="text-3xl font-bold mt-4 text-white">{this.state.videoData?.title}</h1>
                <h5 className="text-l font-bold mt-4 text-white">{this.state.videoData?.views} Views</h5>
                <p className="text-white mt-2">Published on: {this.state.videoData?.createdAt ? moment(this.state.videoData?.createdAt).format("MMMM DD YYYY") : null}</p>

                {/* Like, Share, and Comment Buttons with Icons */}
                <div className="flex space-x-4 mt-4 ">
                  <button onClick={() => this.handleLike(this.state.videoData?._id)} className={`flex items-center text-${this.state?.isLiked ? "red-600" : "white"}`}>
                    <AiOutlineLike className="w-5 h-5 mr-1" fill={this.state?.isLiked ? "red" : "white"} />
                    {this?.state?.totalLike}{" "}
                    Like
                  </button>
                  <button onClick={() => this.handleShare(this.state.videoData?._id)} className="flex items-center text-white">
                    <AiOutlineShareAlt className="w-5 h-5 mr-1" />
                    Share
                  </button>
                  <button onClick={this.handleCommentFocus} className="flex items-center text-white">
                    <FaRegComment className="w-5 h-5 mr-1" />
                    Comment
                  </button>
                </div>
                <div className="mt-8 bg-transparent">
                  <h2 className="text-2xl font-semibold mb-4 text-white">Comments {this.state.responseTotalComment}</h2>
                  <div className="mt-6">
                    <textarea
                      className="w-full bg-transparent p-2 border-b-2 border-b-gray-300 rounded-md"
                      rows="1"
                      placeholder="Add a comment..."
                      value={this.state.comment}
                      onChange={(e) => this.setState({ comment: e.target.value })}
                    ></textarea>
                    <button
                      className="my-2 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition"
                      onClick={this.state.comment ? this.addComment : null}
                    >
                      Post Comment
                    </button>
                  </div>
                  {<div className="bg-transparent p-4 rounded">
                    {this.state.responseCommentData && Array.isArray(this.state.responseCommentData) && this.state.responseCommentData.length > 0 && this.state.responseCommentData?.map((ele, index) => {
                      return <div className="mb-4 flex items-center gap-2">
                        <img src={ele?.user?.profileUrl || ""} alt='' className='w-10 h-10 rounded-full' />
                        <div>
                          <div className='flex gap-2'>
                            <p className="text-white font-semibold underline">{ele?.user?.firstName + " " + ele?.user?.lastName} </p>
                            <span className='text-white'>{timeDifference(ele?.createdAt)}</span>
                          </div>
                          <p className="text-white">{ele?.comment}</p>
                        </div>
                      </div>
                    })}

                  </div>}
                  {(Math.ceil(this.state.responseTotalComment / this.state.commentSize) >= (this.state.commentPageNo)) && <button onClick={this.onScroll}>See More</button>}
                </div>
              </div>

              {/* Related Videos Section */}
              <div className="lg:col-span-1">
                <h2 className="text-2xl font-semibold mb-4 text-white">Related Videos</h2>
                <div className="space-y-4">
                  {/* Related Video Item */}
                  {vidodetailsdata?.length > 0 && vidodetailsdata?.map((ele, id) => {
                    return (
                      <div className="flex items-center w-full gap-4" onClick={() => this.onClickMenu(`/app/videos/${ele?._id}`, ele)}>
                        <div className='w-[30%]'>
                          <img
                            className="w-32 h-24 object-cover rounded"
                            src={ele.image}
                            alt="Related video thumbnail"
                          />
                        </div>
                        <div className="w-[70%]">
                          <h3 className="text-lg font-medium text-white line-clamp-2">{ele.title}</h3>
                          <div className='flex justify-between items-center'>
                            <p className="text-white md:text-sm text-xs">Published on: {ele?.createdAt ? moment(ele?.createdAt).format("MMMM DD YYYY") : null}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <button onClick={() => this.onClickMenu("/app/video")} className="w-full text-white font-medium py-3 rounded-md bg-[#6515A6]">View All Related Videos</button>
                  </div>
                </div>
              </div>
            </div>


          </section>
        </div>
      </>
    );
  }

}
function mapStateToProps(state, preProps) {
  const { users, authentication } = state;

  return {
    users,
    authentication,
  };
}
export default connect(mapStateToProps)(Video);


