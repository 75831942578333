import React, { Component } from 'react';
import "jspdf-autotable";
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import { MdArrowBack, MdShare } from "react-icons/md";
import { FaSkype, FaCopy } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { FaUser } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';



class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 5,
      page: 1,
      errorsticket: {},
      fieldsticket: {},
      ticketTitle: "",
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      isResolved: null,
      selectedValue: "",
      isModalOpen: false,
      copied: false
    }
  }

  componentDidMount() {
    this.NewsDetail_page();
  }

  NewsDetail_page = () => {
    let parameter = {
      id: this.props.match.params.id
    };
    console.log(parameter);
    this.props.dispatch(userActions.getNewsby_detail(parameter));
    this.props.dispatch(userActions.shareViewUpdateNews({id :parameter.id , share:false}))
  }

  handleGoBack = () => {
    window.history.back();
  };

  handleShare = () => {
    const { newsdetailsdata } = this.props;
    if (navigator.share) {
      navigator.share({
        title: newsdetailsdata.title,
        text: newsdetailsdata.title,
        url: window.location.href,
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      // Fallback for browsers that do not support the Share API
      alert('Sharing is not supported in this browser. Copy the link: ' + window.location.href);
    }
  };

  copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  handleShareClick = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };


  handleCopy = () => {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000);
  };

  render() {
    const { users } = this.props;
    const { newsdetailsdata } = users;
    const { isModalOpen } = this.state;
    const shareUrl = window.location.href;
    const title = newsdetailsdata ? newsdetailsdata.title : '';
    const imageUrl = newsdetailsdata ? newsdetailsdata.image : '';


    const { copied } = this.state;

    return (
      <>
        <Helmet>
          <title>{title ? title : 'News'}</title>
          {imageUrl && <meta property="og:image" content={imageUrl} />}
          <meta name="description" content={newsdetailsdata ? newsdetailsdata.title : 'News article'} />
        </Helmet>

        <section className="w-full mx-auto bg-[#1C2745] overflow-hidden h-screen overflow-y-auto custom-scroll">
          <div className='bg-[#1C2745] fixed w-full'>
            <div className='py-10 xl:w-6/12 w-11/12 mx-auto flex justify-between items-center' >
              <div onClick={this.handleGoBack}>
                <button className='flex justify-center items-center gap-4 p-2 bg-[#1C2745] border text-white rounded-full' type=""><MdArrowBack size={24} className='border border-white rounded-full' /></button>
              </div>
              <div>
                <h1 className='text-2xl text-white'> Vunecoin News</h1>
              </div>
              <div>
                <button className='flex justify-center items-center p-2 bg-[#040505] text-white rounded-full border' onClick={this.handleShareClick} type="">
                  <MdShare size={24} />
                </button>
              </div>
            </div>
          </div>
          <div className='pt-32 py-6'>
            <section className="xl:w-6/12 w-11/12 mx-auto md:p-10 p-4  pb-10 bg-white">
              <div className='divide-y divide-gray-600 space-y-6'>
                {newsdetailsdata ? (
                  <div className="space-y-4 md:space-y-4 py-4">
                    <div className='md:px-4'>
                      <h1 className='md:text-4xl text-xl font-bold text-black'>{newsdetailsdata.title}</h1>
                    </div>
                    <div className="flex justify-center 2xl:justify-start w-full text-black pb-4 lg:pb-0">
                      <img className="rounded-md w-full" alt="news" src={newsdetailsdata.image} width={500} height={300} />
                    </div>
                    <p className="text-base text-black inline-flex items-center gap-2"><span className='flex items-center gap-2'><FaUser /> {newsdetailsdata && newsdetailsdata.author ? newsdetailsdata.author : "-"}</span>  {moment(new Date(parseInt(newsdetailsdata && newsdetailsdata.createdAt ? newsdetailsdata.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")} </p>
                    <div className="lg:w-full  mx-auto text-black space-y-4" dangerouslySetInnerHTML={{ __html: newsdetailsdata.story }}></div>
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </section>
          </div>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={this.closeModal}
            contentLabel="Share Modal"
            className="modal flex items-center justify-center"
            overlayClassName="overlay"
          >
            <div className={isModalOpen ? "fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/80" : "hidden"}>
              <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-[#687083] rounded-lg border border-[#6514A6] shadow-lg modal-container md:max-w-md">
                <div className="px-6 py-4 text-left modal-content">
                  <div className="flex items-center justify-between pb-3">
                    <p className="text-2xl text-white font-bold">Share this article</p>
                    <div className="z-50 cursor-pointer modal-close p-1 rounded-full bg-white">
                      <svg onClick={this.closeModal} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                      </svg>
                    </div>
                  </div>
                  <div className='flex justify-center items-center py-10 space-x-4'>
                    <FacebookShareButton url={shareUrl} quote={title}>
                      <FacebookIcon size={34} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} title={title}>
                      <TwitterIcon size={34} round />
                    </TwitterShareButton>
                    <WhatsappShareButton url={shareUrl} title={title}>
                      <WhatsappIcon size={34} round />
                    </WhatsappShareButton>
                    <LinkedinShareButton url={shareUrl} title={title}>
                      <LinkedinIcon size={34} round />
                    </LinkedinShareButton>
                    <a
                      href={`https://web.skype.com/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex justify-center items-center"
                    >
                      <FaSkype size={34} round color='#00AFF0' />
                    </a>
                    <CopyToClipboard text={shareUrl} onCopy={this.handleCopy}>
                      <button className="flex items-center justify-center rounded-full shadow-md">
                        <FaCopy size={24} color='#fff' />
                      </button>
                    </CopyToClipboard>
                    {copied && <span className="text-white">Copied!</span>}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}

export default connect(mapStateToProps)(News);
