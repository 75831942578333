export const userConstants = {

    USER_VIDEO_LIST_REQUEST: "USER_VIDEO_LIST_REQUEST",
    USER_VIDEO_LIST_SUCCESS: "USER_VIDEO_LIST_SUCCESS",
    USER_VIDEO_LIST_FAILURE: "USER_VIDEO_LIST_FAILURE",



    USER_NEWS_REQUEST: 'USER_NEWS_REQUEST',
    USER_NEWS_SUCCESS: 'USER_NEWS_SUCCESS',
    USER_NEWS_FAILURE: 'USER_NEWS_FAILURE',



    READ_NOTIFICATION_REQUEST: "READ_NOTIFICATION_REQUEST",
    READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
    READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",



    REMOVE_IMAGE_REQUEST: "REMOVE_IMAGE_REQUEST",
    REMOVE_IMAGE_SUCCESS: "REMOVE_IMAGE_SUCCESS",
    REMOVE_IMAGE_FAILURE: "REMOVE_IMAGE_FAILURE",
    VIEW_TICKET_MESSAGE_REQUEST: "VIEW_TICKET_MESSAGE_REQUEST",
    VIEW_TICKET_MESSAGE_SUCCESS: "VIEW_TICKET_MESSAGE_SUCCESS",
    VIEW_TICKET_MESSAGE_FAILURE: "VIEW_TICKET_MESSAGE_FAILURE",
    CLEAR_STORE_IMAGE: "CLEAR_STORE_IMAGE",
    CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
    CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
    CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',

    GET_ALL_COIN_REQUEST: 'GET_ALL_COIN_REQUEST',
    GET_ALL_COIN_SUCCESS: 'GET_ALL_COIN_SUCCESS',
    GET_ALL_COIN_FAILURE: 'GET_ALL_COIN_FAILURE',

    COIN_SWAP_REQUEST: 'COIN_SWAP_REQUEST',
    COIN_SWAP_SUCCESS: 'COIN_SWAP_SUCCESS',
    COIN_SWAP_FAILURE: 'COIN_SWAP_FAILURE',

    GET_NEWS_REQUEST: 'GET_NEWS_REQUEST',
    GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
    GET_NEWS_FAILURE: 'GET_NEWS_FAILURE',



    GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
    GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',


    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',




    UPDATE_PROFILE_PICTURE_REQUEST: 'UPDATE_PROFILE_PICTURE_REQUEST',
    UPDATE_PROFILE_PICTURE_SUCCESS: 'UPDATE_PROFILE_PICTURE_SUCCESS',
    UPDATE_PROFILE_PICTURE_FAILURE: 'UPDATE_PROFILE_PICTURE_FAILURE',


    SEND_COIN_OTP_VERIFICATION_REQUEST: 'SEND_COIN_OTP_VERIFICATION_REQUEST',
    SEND_COIN_OTP_VERIFICATION_SUCCESS: 'SEND_COIN_OTP_VERIFICATION_SUCCESS',
    SEND_COIN_OTP_VERIFICATION_FAILURE: 'SEND_COIN_OTP_VERIFICATION_FAILURE',

    CLEAR_CACHE_SUCCESS: 'CLEAR_CACHE_SUCCESS',

    ADD_USER_TOKEN_BALANCE_IN_WALLET_REQUEST: 'ADD_USER_TOKEN_BALANCE_IN_WALLET_REQUEST',
    ADD_USER_TOKEN_BALANCE_IN_WALLET_SUCCESS: 'ADD_USER_TOKEN_BALANCE_IN_WALLET_SUCCESS',
    ADD_USER_TOKEN_BALANCE_IN_WALLET_FAILURE: 'ADD_USER_TOKEN_BALANCE_IN_WALLET_FAILURE',

    GET_BPT_DETAIS_REQUEST: 'GET_BPT_DETAIS_REQUEST',
    GET_BPT_DETAIS_SUCCESS: 'GET_BPT_DETAIS_SUCCESS',
    GET_BPT_DETAIS_FAILURE: 'GET_BPT_DETAIS_FAILURE',

    GET_COUNTRY_CODE_LIST_REQUEST: 'GET_COUNTRY_CODE_LIST_REQUEST',
    GET_COUNTRY_CODE_LIST_SUCCESS: 'GET_COUNTRY_CODE_LIST_SUCCESS',
    GET_COUNTRY_CODE_LIST_FAILURE: 'GET_COUNTRY_CODE_LIST_FAILURE',

    GET_ALL_COIN_REMOVE_ID_REQUEST: 'GET_ALL_COIN_REMOVE_ID_REQUEST',
    GET_ALL_COIN_REMOVE_ID_SUCCESS: 'GET_ALL_COIN_REMOVE_ID_SUCCESS',
    GET_ALL_COIN_REMOVE_ID_FAILURE: 'GET_ALL_COIN_REMOVE_ID_FAILURE',

    GET_RECIEVE_COIN_REQUEST: 'GET_RECIEVE_COIN_REQUEST',
    GET_RECIEVE_COIN_SUCCESS: 'GET_RECIEVE_COIN_SUCCESS',
    GET_RECIEVE_COIN_FAILURE: 'GET_RECIEVE_COIN_FAILURE',

    GET_USER_PROMO_PACKAGE_BY_USER_ID_REQUEST: 'GET_USER_PROMO_PACKAGE_BY_USER_ID_REQUEST',
    GET_USER_PROMO_PACKAGE_BY_USER_ID_SUCCESS: 'GET_USER_PROMO_PACKAGE_BY_USER_ID_SUCCESS',
    GET_USER_PROMO_PACKAGE_BY_USER_ID_FAILURE: 'GET_USER_PROMO_PACKAGE_BY_USER_ID_FAILURE',

    CREATE_USER_PROMO_PACKAGE_REQUEST: 'CREATE_USER_PROMO_PACKAGE_REQUEST',
    CREATE_USER_PROMO_PACKAGE_SUCCESS: 'CREATE_USER_PROMO_PACKAGE_SUCCESS',
    CREATE_USER_PROMO_PACKAGE_FAILURE: 'CREATE_USER_PROMO_PACKAGE_FAILURE',

    GET_ADDRESS_DEPOSIT_TIME_REQUEST: 'GET_ADDRESS_DEPOSIT_TIME_REQUEST',
    GET_ADDRESS_DEPOSIT_TIME_SUCCESS: 'GET_ADDRESS_DEPOSIT_TIME_SUCCESS',
    GET_ADDRESS_DEPOSIT_TIME_FAILURE: 'GET_ADDRESS_DEPOSIT_TIME_FAILURE',

    CREATE_NOTIFICATION_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
    CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
    CREATE_NOTIFICATION_FAILURE: 'CREATE_NOTIFICATION_FAILURE',

    GET_ALL_LEVEL_MINING_REQUEST: 'GET_ALL_LEVEL_MINING_REQUEST',
    GET_ALL_LEVEL_MINING_SUCCESS: 'GET_ALL_LEVEL_MINING_SUCCESS',
    GET_ALL_LEVEL_MINING_FAILURE: 'GET_ALL_LEVEL_MINING_FAILURE',

    GET_LEVEL_BY_ID_USER_PACKAGE_REQUEST: 'GET_LEVEL_BY_ID_USER_PACKAGE_REQUEST',
    GET_LEVEL_BY_ID_USER_PACKAGE_SUCCESS: 'GET_LEVEL_BY_ID_USER_PACKAGE_SUCCESS',
    GET_LEVEL_BY_ID_USER_PACKAGE_FAILURE: 'GET_LEVEL_BY_ID_USER_PACKAGE_FAILURE',

    REPLY_TICKET_BY_USER__REQUEST: 'REPLY_TICKET_BY_USER__REQUEST',
    REPLY_TICKET_BY_USER__SUCCESS: 'REPLY_TICKET_BY_USER__SUCCESS',
    REPLY_TICKET_BY_USER__FAILURE: 'REPLY_TICKET_BY_USER__FAILURE',

    GET_LEVEL_BY_ID_USER_TX_REQUEST: 'GET_LEVEL_BY_ID_USER_TX_REQUEST',
    GET_LEVEL_BY_ID_USER_TX_SUCCESS: 'GET_LEVEL_BY_ID_USER_TX_SUCCESS',
    GET_LEVEL_BY_ID_USER_TX_FAILURE: 'GET_LEVEL_BY_ID_USER_TX_FAILURE',

    GET_LEVEL_BY_ID_USER_REQUEST: 'GET_LEVEL_BY_ID_USER_REQUEST',
    GET_LEVEL_BY_ID_USER_SUCCESS: 'GET_LEVEL_BY_ID_USER_SUCCESS',
    GET_LEVEL_BY_ID_USER_FAILURE: 'GET_LEVEL_BY_ID_USER_FAILURE',

    GET_ALL_LEVEL_WELCOME_REQUEST: 'GET_ALL_LEVEL_WELCOME_REQUEST',
    GET_ALL_LEVEL_WELCOME_SUCCESS: 'GET_ALL_LEVEL_WELCOME_SUCCESS',
    GET_ALL_LEVEL_WELCOME_FAILURE: 'GET_ALL_LEVEL_WELCOME_FAILURE',


    GET_USER_LIST_BY_EMAIL_REQUEST: 'GET_USER_LIST_BY_EMAIL_REQUEST',
    GET_USER_LIST_BY_EMAIL_SUCCESS: 'GET_USER_LIST_BY_EMAIL_SUCCESS',
    GET_USER_LIST_BY_EMAIL_FAILURE: 'GET_USER_LIST_BY_EMAIL_FAILURE',

    WITHDRAW_BALANCE_REQUEST: 'WITHDRAW_BALANCE_REQUEST',
    WITHDRAW_BALANCE_SUCCESS: 'WITHDRAW_BALANCE_SUCCESS',
    WITHDRAW_BALANCE_FAILURE: 'WITHDRAW_BALANCE_FAILURE',

    GET_COIN_CMC_REQUEST: 'GET_COIN_CMC_REQUEST',
    GET_COIN_CMC_SUCCESS: 'GET_COIN_CMC_SUCCESS',
    GET_COIN_CMC_FAILURE: 'GET_COIN_CMC_FAILURE',

    CLAIM_ADDRESS_REQUEST: 'CLAIM_ADDRESS_REQUEST',
    CLAIM_ADDRESS_SUCCESS: 'CLAIM_ADDRESS_SUCCESS',
    CLAIM_ADDRESS_FAILURE: 'CLAIM_ADDRESS_FAILURE',

    SAVE_DFT_WALLET_ADDRESS_REQUEST: 'SAVE_DFT_WALLET_ADDRESS_REQUEST',
    SAVE_DFT_WALLET_ADDRESS_SUCCESS: 'SAVE_DFT_WALLET_ADDRESS_SUCCESS',
    SAVE_DFT_WALLET_ADDRESS_FAILURE: 'SAVE_DFT_WALLET_ADDRESS_FAILURE',

    GET_DFT_WALLET_REQUEST: 'GET_DFT_WALLET_REQUEST',
    GET_DFT_WALLET_SUCCESS: 'GET_DFT_WALLET_SUCCESS',
    GET_DFT_WALLET_FAILURE: 'GET_DFT_WALLET_FAILURE',

    GET_PACKAGE_LIST_REQUEST: 'GET_PACKAGE_LIST_REQUEST',
    GET_PACKAGE_LIST_SUCCESS: 'GET_PACKAGE_LIST_SUCCESS',
    GET_PACKAGE_LIST_FAILURE: 'GET_PACKAGE_LIST_FAILURE',

    GET_TICKET_BY_ID_LIST_REQUEST: 'GET_TICKET_BY_ID_LIST_REQUEST',
    GET_TICKET_BY_ID_LIST_SUCCESS: 'GET_TICKET_BY_ID_LIST_SUCCESS',
    GET_TICKET_BY_ID_LIST_FAILURE: 'GET_TICKET_BY_ID_LIST_FAILURE',

    GET_ALL_CITY_REQUEST: 'GET_ALL_CITY_REQUEST',
    GET_ALL_CITY_SUCCESS: 'GET_ALL_CITY_SUCCESS',
    GET_ALL_CITY_FAILURE: 'GET_ALL_CITY_FAILURE',

    TX_LIST_BY_DATE_REQUEST: 'TX_LIST_BY_DATE_REQUEST',
    TX_LIST_BY_DATE_SUCCESS: 'TX_LIST_BY_DATE_SUCCESS',
    TX_LIST_BY_DATE_FAILURE: 'TX_LIST_BY_DATE_FAILURE',

    GET_ALL_STATE_REQUEST: 'GET_ALL_STATE_REQUEST',
    GET_ALL_STATE_SUCCESS: 'GET_ALL_STATE_SUCCESS',
    GET_ALL_STATE_FAILURE: 'GET_ALL_STATE_FAILURE',

    GET_ALL_COIN_INDEX_BY_INDEX_ID_REQUEST: 'GET_ALL_COIN_INDEX_BY_INDEX_ID_REQUEST',
    GET_ALL_COIN_INDEX_BY_INDEX_ID_SUCCESS: 'GET_ALL_COIN_INDEX_BY_INDEX_ID_SUCCESS',
    GET_ALL_COIN_INDEX_BY_INDEX_ID_FAILURE: 'GET_ALL_COIN_INDEX_BY_INDEX_ID_FAILURE',

    GET_INR_COIN_ID_REQUEST: 'GET_INR_COIN_ID_REQUEST',
    GET_INR_COIN_ID_SUCCESS: 'GET_INR_COIN_ID_SUCCESS',
    GET_INR_COIN_ID_FAILURE: 'GET_INR_COIN_ID_FAILURE',

    GET_USER_NAME_VERIFIED_REQUEST: 'GET_USER_NAME_VERIFIED_REQUEST',
    GET_USER_NAME_VERIFIED_SUCCESS: 'GET_USER_NAME_VERIFIED_SUCCESS',
    GET_USER_NAME_VERIFIED_FAILURE: 'GET_USER_NAME_VERIFIED_FAILURE',

    CREATE_MAKE_PAYMENT_REQUEST: 'CREATE_MAKE_PAYMENT_REQUEST',
    CREATE_MAKE_PAYMENT_SUCCESS: 'CREATE_MAKE_PAYMENT_SUCCESS',
    CREATE_MAKE_PAYMENT_FAILURE: 'CREATE_MAKE_PAYMENT_FAILURE',

    GET_ALL_COUNTRY_REQUEST: 'GET_ALL_COUNTRY_REQUEST',
    GET_ALL_COUNTRY_SUCCESS: 'GET_ALL_COUNTRY_SUCCESS',
    GET_ALL_COUNTRY_FAILURE: 'GET_ALL_COUNTRY_FAILURE',

    SELL_INDEX_REQUEST: 'SELL_INDEX_REQUEST',
    SELL_INDEX_SUCCESS: 'SELL_INDEX_SUCCESS',
    SELL_INDEX_FAILURE: 'SELL_INDEX_FAILURE',

    CREATE_WALLET_REQUEST: 'CREATE_WALLET_REQUEST',
    CREATE_WALLET_SUCCESS: 'CREATE_WALLET_SUCCESS',
    CREATE_WALLET_FAILURE: 'CREATE_WALLET_FAILURE',

    GET_INDEX_ORDER_REQUEST: 'GET_INDEX_ORDER_REQUEST',
    GET_INDEX_ORDER_SUCCESS: 'GET_INDEX_ORDER_SUCCESS',
    GET_INDEX_ORDER_FAILURE: 'GET_INDEX_ORDER_FAILURE',

    GET_COIN_BALANCE_REQUEST: 'GET_COIN_BALANCE_REQUEST',
    GET_COIN_BALANCE_SUCCESS: 'GET_COIN_BALANCE_SUCCESS',
    GET_COIN_BALANCE_FAILURE: 'GET_COIN_BALANCE_FAILURE',

    BUY_INDEX_REQUEST: 'BUY_INDEX_REQUEST',
    BUY_INDEX_SUCCESS: 'BUY_INDEX_SUCCESS',
    BUY_INDEX_FAILURE: 'BUY_INDEX_FAILURE',

    GET_ALL_INDEX_REQUEST: 'GET_ALL_INDEX_REQUEST',
    GET_ALL_INDEX_SUCCESS: 'GET_ALL_INDEX_SUCCESS',
    GET_ALL_INDEX_FAILURE: 'GET_ALL_INDEX_FAILURE',

    GET_WALLET_LIST_REQUEST: 'GET_WALLET_LIST_REQUEST',
    GET_WALLET_LIST_SUCCESS: 'GET_WALLET_LIST_SUCCESS',
    GET_WALLET_LIST_FAILURE: 'GET_WALLET_LIST_FAILURE',

    SAVE_KYC_DOC_SELFIE_REQUEST: 'SAVE_KYC_DOC_SELFIE_REQUEST',
    SAVE_KYC_DOC_SELFIE_SUCCESS: 'SAVE_KYC_DOC_SELFIE_SUCCESS',
    SAVE_KYC_DOC_SELFIE_FAILURE: 'SAVE_KYC_DOC_SELFIE_FAILURE',

    SAVE_KYC_DOC_PASSPORT_REQUEST: 'SAVE_KYC_DOC_PASSPORT_REQUEST',
    SAVE_KYC_DOC_PASSPORT_SUCCESS: 'SAVE_KYC_DOC_PASSPORT_SUCCESS',
    SAVE_KYC_DOC_PASSPORT_FAILURE: 'SAVE_KYC_DOC_PASSPORT_FAILURE',

    SAVE_KYC_DOC_PAN_REQUEST: 'SAVE_KYC_DOC_PAN_REQUEST',
    SAVE_KYC_DOC_PAN_SUCCESS: 'SAVE_KYC_DOC_PAN_SUCCESS',
    SAVE_KYC_DOC_PAN_FAILURE: 'SAVE_KYC_DOC_PAN_FAILURE',

    SAVE_KYC_DOC_AADHAR_REQUEST: 'SAVE_KYC_DOC_AADHAR_REQUEST',
    SAVE_KYC_DOC_AADHAR_SUCCESS: 'SAVE_KYC_DOC_AADHAR_SUCCESS',
    SAVE_KYC_DOC_AADHAR_FAILURE: 'SAVE_KYC_DOC_AADHAR_FAILURE',

    SAVE_KYC_DOC_REQUEST: 'SAVE_KYC_DOC_REQUEST',
    SAVE_KYC_DOC_SUCCESS: 'SAVE_KYC_DOC_SUCCESS',
    SAVE_KYC_DOC_FAILURE: 'SAVE_KYC_DOC_FAILURE',

    GET_DOCUMENT_LIST_OBJ_REQUEST: 'GET_DOCUMENT_LIST_OBJ_REQUEST',
    GET_DOCUMENT_LIST_OBJ_SUCCESS: 'GET_DOCUMENT_LIST_OBJ_SUCCESS',
    GET_DOCUMENT_LIST_OBJ_FAILURE: 'GET_DOCUMENT_LIST_OBJ_FAILURE',

    SAVE_USER_INFO_REQUEST: 'SAVE_USER_INFO_REQUEST',
    SAVE_USER_INFO_SUCCESS: 'SAVE_USER_INFO_SUCCESS',
    SAVE_USER_INFO_FAILURE: 'SAVE_USER_INFO_FAILURE',

    SAVE_BTST_REQUEST: 'SAVE_BTST_REQUEST',
    SAVE_BTST_SUCCESS: 'SAVE_BTST_SUCCESS',
    SAVE_BTST_FAILURE: 'SAVE_BTST_FAILURE',

    SAVE_STAKE_REQUEST: 'SAVE_STAKE_REQUEST',
    SAVE_STAKE_SUCCESS: 'SAVE_STAKE_SUCCESS',
    SAVE_STAKE_FAILURE: 'SAVE_STAKE_FAILURE',


    USER_TICKET_CREATE_REQUEST: 'USER_TICKET_CREATE_REQUEST',
    USER_TICKET_CREATE_SUCCESS: 'USER_TICKET_CREATE_SUCCESS',
    USER_TICKET_CREATE_FAILURE: 'USER_TICKET_CREATE_FAILURE',

    UPDATE_PASSWORD_VALIDATE_OTP_REQUEST: 'UPDATE_PASSWORD_VALIDATE_OTP_REQUEST',
    UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS: 'UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS',
    UPDATE_PASSWORD_VALIDATE_OTP_FAILURE: 'UPDATE_PASSWORD_VALIDATE_OTP_FAILURE',

    VALIDATE_LOGIN_OTP_REQUEST: 'VALIDATE_LOGIN_OTP_REQUEST',
    VALIDATE_LOGIN_OTP_SUCCESS: 'VALIDATE_LOGIN_OTP_SUCCESS',
    VALIDATE_LOGIN_OTP_FAILURE: 'VALIDATE_LOGIN_OTP_FAILURE',

    FORGOT_PASS_UPDATE_REQUEST: 'USERS_FORGOT_PASS_UPDATE_REQUEST',
    FORGOT_PASS_UPDATE_SUCCESS: 'USERS_FORGOT_PASS_UPDATE_SUCCESS',
    FORGOT_PASS_UPDATE_FAILURE: 'USERS_FORGOT_PASS_UPDATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_BTST_REQUEST: 'USERS_GET_BTST_REQUEST',
    GET_BTST_SUCCESS: 'USERS_GET_BTST_SUCCESS',
    GET_BTST_FAILURE: 'USERS_GET_BTST_FAILURE',

    GET_STAKE_REQUEST: 'USERS_GET_STAKE_REQUEST',
    GET_STAKE_SUCCESS: 'USERS_GET_STAKE_SUCCESS',
    GET_STAKE_FAILURE: 'USERS_GET_STAKE_FAILURE',

    GET_PACKAGE_BTST_REQUEST: 'USERS_GET_PACKAGE_BTST_REQUEST',
    GET_PACKAGE_BTST_SUCCESS: 'USERS_GET_PACKAGE_BTST_SUCCESS',
    GET_PACKAGE_BTST_FAILURE: 'USERS_GET_PACKAGE_BTST_FAILURE',

    GET_PACKAGE_STAKE_REQUEST: 'USERS_GET_PACKAGE_STAKE_REQUEST',
    GET_PACKAGE_STAKE_SUCCESS: 'USERS_GET_PACKAGE_STAKE_SUCCESS',
    GET_PACKAGE_STAKE_FAILURE: 'USERS_GET_PACKAGE_STAKE_FAILURE',

    SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

    LOGIN_FIRST_REQUEST: 'USERS_FIRST_LOGIN_REQUEST',
    LOGIN_FIRST_SUCCESS: 'USERS_FIRST_LOGIN_SUCCESS',
    LOGIN_FIRST_FAILURE: 'USERS_FIRST_LOGIN_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',


    SEND_COIN_REQUEST: 'SEND_COIN__REQUEST',
    SEND_COIN_SUCCESS: 'SEND_COIN__SUCCESS',
    SEND_COIN_FAILURE: 'SEND_COIN__FAILURE',

    SEND_BALANCE_REQUEST: 'SEND_BALANCE__REQUEST',
    SEND_BALANCE_SUCCESS: 'SEND_BALANCE__SUCCESS',
    SEND_BALANCE_FAILURE: 'SEND_BALANCE__FAILURE',

    TX_LIST_REQUEST: 'TX_LIST__REQUEST',
    TX_LIST_SUCCESS: 'TX_LIST__SUCCESS',
    TX_LIST_FAILURE: 'TX_LIST__FAILURE',

    TX_LIST_OVERFLOW_REQUEST: 'TX_LIST_OVERFLOW_REQUEST',
    TX_LIST_OVERFLOW_SUCCESS: 'TX_LIST_OVERFLOW_SUCCESS',
    TX_LIST_OVERFLOW_FAILURE: 'TX_LIST_OVERFLOW_FAILURE',

    GET_USER_WALLET_REQUEST: 'GET_USER_WALLET__REQUEST',
    GET_USER_WALLET_SUCCESS: 'GET_USER_WALLET__SUCCESS',
    GET_USER_WALLET_FAILURE: 'GET_USER_WALLET__FAILURE',

    FORGOT_FIRST_REQUEST: 'USERS_FIRST_FORGOT_REQUEST',
    FORGOT_FIRST_SUCCESS: 'USERS_FIRST_FORGOT_SUCCESS',
    FORGOT_FIRST_FAILURE: 'USERS_FIRST_FORGOT_FAILURE',

    SEND_REQUEST: 'USERS_SEND_REQUEST',
    SEND_SUCCESS: 'USERS_SEND_SUCCESS',
    SEND_FAILURE: 'USERS_SEND_FAILURE',

    SEND_OTP_REQUEST: 'USERS_SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'USERS_SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'USERS_SEND_OTP_FAILURE',

    TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    CMC_COIN_BY_TICKER_NAME_REQUEST: 'CMC_COIN_BY_TICKER_NAME_REQUEST',
    CMC_COIN_BY_TICKER_NAME_SUCCESS: 'CMC_COIN_BY_TICKER_NAME_SUCCESS',
    CMC_COIN_BY_TICKER_NAME_FAILURE: 'CMC_COIN_BY_TICKER_NAME_FAILURE',

    PROFILE_PI_REQUEST: 'PROFILE_PI_REQUEST',
    PROFILE_PI_SUCCESS: 'PROFILE_PI_SUCCESS',
    PROFILE_PI_FAILURE: 'PROFILE_PI_FAILURE',

    USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

    SOCIAL_DETAILS_REQUEST: 'SOCIAL_DETAILS_REQUEST',
    SOCIAL_DETAILS_SUCCESS: 'SOCIAL_DETAILS_SUCCESS',
    SOCIAL_DETAILS_FAILURE: 'SOCIAL_DETAILS_FAILURE',

    UPDATE_USER_DETAILS_REQUEST: 'UPDATE_USER_DETAILS_REQUEST',
    UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
    UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',

    PRICE_REQUEST: 'PRICE_REQUEST',
    PRICE_SUCCESS: 'PRICE_SUCCESS',
    PRICE_FAILURE: 'PRICE_FAILURE',

    SWAP_REQUEST: 'SWAP_REQUEST',
    SWAP_SUCCESS: 'SWAP_SUCCESS',
    SWAP_FAILURE: 'SWAP_FAILURE',


    USER_DOC_LIST_REQUEST: 'USER_DOC_LIST_REQUEST',
    USER_DOC_LIST_SUCCESS: 'USER_DOC_LIST_SUCCESS',
    USER_DOC_LIST_FAILURE: 'USER_DOC_LIST_FAILURE',

    GET_KYC_REQUEST: 'GET_KYC_REQUEST',
    GET_KYC_SUCCESS: 'GET_KYC_SUCCESS',
    GET_KYC_FAILURE: 'GET_KYC_FAILURE',

    USER_KYC_CREATE_REQUEST: 'USER_KYC_CREATE_REQUEST',
    USER_KYC_CREATE_SUCCESS: 'USER_KYC_CREATE_SUCCESS',
    USER_KYC_CREATE_FAILURE: 'USER_KYC_CREATE_FAILURE',


    GET_MAKE_PAYMENT_LIST_REQUEST: 'GET_MAKE_PAYMENT_LIST_REQUEST',
    GET_MAKE_PAYMENT_LIST_SUCCESS: 'GET_MAKE_PAYMENT_LIST_SUCCESS',
    GET_MAKE_PAYMENT_LIST_FAILURE: 'GET_MAKE_PAYMENT_LIST_FAILURE',


    ADD_IMAGE_BPT_REQUEST: 'ADD_IMAGE_BPT_REQUEST',
    ADD_IMAGE_BPT_SUCCESS: 'ADD_IMAGE_BPT_SUCCESS',
    ADD_IMAGE_BPT_FAILURE: 'ADD_IMAGE_BPT_FAILURE',

    USER_TICKET_LIST_REQUEST: 'USER_TICKET_LIST_REQUEST',
    USER_TICKET_LIST_SUCCESS: 'USER_TICKET_LIST_SUCCESS',
    USER_TICKET_LIST_FAILURE: 'USER_TICKET_LIST_FAILURE',

    CREATE_TICKET_REQUEST: 'CREATE_TICKET_REQUEST',
    CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
    CREATE_TICKET_FAILURE: 'CREATE_TICKET_FAILURE',

    GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',


    FILE_UPLOAD_SELFIE_STATUS_REQUEST: "FILE_UPLOAD_SELFIE_STATUS_REQUEST",
    FILE_UPLOAD_SELFIE_STATUS_SUCCESS: 'FILE_UPLOAD_SELFIE_STATUS_SUCCESS',
    FILE_UPLOAD_SELFIE_CLEAR_SUCCESS: 'FILE_UPLOAD_SELFIE_CLEAR_SUCCESS',

    FILE_UPLOAD_SELFIE_STATUS_FAILURE: 'FILE_UPLOAD_SELFIE_STATUS_FAILURE',

    FILE_UPLOAD_STATUS_PAN_REQUEST: 'FILE_UPLOAD_STATUS_PAN_REQUEST',
    FILE_UPLOAD_STATUS_PAN_SUCCESS: 'FILE_UPLOAD_STATUS_PAN_SUCCESS',
    FILE_UPLOAD_STATUS_PAN_FAILURE: 'FILE_UPLOAD_STATUS_PAN_FAILURE',

    FILE_UPLOAD_STATUS_ADHAAR_REQUEST: 'FILE_UPLOAD_STATUS_ADHAAR_REQUEST',
    FILE_UPLOAD_STATUS_ADHAAR_SUCCESS: 'FILE_UPLOAD_STATUS_ADHAAR_SUCCESS',
    FILE_UPLOAD_STATUS_ADHAAR_FAILURE: 'FILE_UPLOAD_STATUS_ADHAAR_FAILURE',

    FILE_UPLOAD_STATUS_ADHAAR_BACK_REQUEST: 'FILE_UPLOAD_STATUS_ADHAAR_BACK_REQUEST',
    FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS: 'FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS',
    FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE: 'FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE',

    FILE_UPLOAD_STATUS_PASSPORT_REQUEST: 'FILE_UPLOAD_STATUS_PASSPORT_REQUEST',
    FILE_UPLOAD_STATUS_PASSPORT_SUCCESS: 'FILE_UPLOAD_STATUS_PASSPORT_SUCCESS',
    FILE_UPLOAD_STATUS_PASSPORT_FAILURE: 'FILE_UPLOAD_STATUS_PASSPORT_FAILURE',

    FILE_UPLOAD_CLEAR_PAN_SUCCESS: 'FILE_UPLOAD_CLEAR_PAN_SUCCESS',
    FILE_UPLOAD_CLEAR_PASSPORT_SUCCESS: 'FILE_UPLOAD_CLEAR_PASSPORT_SUCCESS',
    FILE_UPLOAD_CLEAR_ADHAAR_SUCCESS: 'FILE_UPLOAD_CLEAR_ADHAAR_SUCCESS',

    FILE_UPLOAD_CLEAR_ADHAAR_BACK_SUCCESS: 'FILE_UPLOAD_CLEAR_ADHAAR_BACK_SUCCESS',


    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    RESET_USER_REDUX: 'RESET_USER_REDUX',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',


    SWAP_COIN_AMOUNT_REQUEST: 'SWAP_COIN_AMOUNT_REQUEST',
    SWAP_COIN_AMOUNT_SUCCESS: 'SWAP_COIN_AMOUNT_SUCCESS',
    SWAP_COIN_AMOUNT_FAILURE: 'SWAP_COIN_AMOUNT_FAILURE',


    GET_USER_WALLET_BY_COIN_NAME_REQUEST: 'GET_USER_WALLET_BY_COIN_NAME_REQUEST',
    GET_USER_WALLET_BY_COIN_NAME_SUCCESS: 'GET_USER_WALLET_BY_COIN_NAME_SUCCESS',
    GET_USER_WALLET_BY_COIN_NAME_FAILURE: 'GET_USER_WALLET_BY_COIN_NAME_FAILURE',


    REGISTER_FIRST_REQUEST: 'USERS_FIRST_REGISTER_REQUEST',
    REGISTER_FIRST_SUCCESS: 'USERS_FIRST_REGISTER_SUCCESS',
    REGISTER_FIRST_FAILURE: 'USERS_FIRST_REGISTER_FAILURE',

    COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
    COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
    COUNTRY_LIST_FAILURE: 'COUNTRY_LIST_FAILURE',

    CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
    CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
    CITY_LIST_FAILURE: 'CITY_LIST_FAILURE',

    STATES_LIST_REQUEST: 'STATES_LIST_REQUEST',
    STATES_LIST_SUCCESS: 'STATES_LIST_SUCCESS',
    STATES_LIST_FAILURE: 'STATES_LIST_FAILURE',

    GET_REFERAL_BY_ID_REQUEST: 'GET_REFERAL_BY_ID_REQUEST',
    GET_REFERAL_BY_ID_SUCCESS: 'GET_REFERAL_BY_ID_SUCCESS',
    GET_REFERAL_BY_ID_FAILURE: 'GET_REFERAL_BY_ID_FAILURE',

    GET_REFERAL_COUNT_AND_SUM_REQUEST: 'GET_REFERAL_COUNT_AND_SUM_REQUEST',
    GET_REFERAL_COUNT_AND_SUM_SUCCESS: 'GET_REFERAL_COUNT_AND_SUM_SUCCESS',
    GET_REFERAL_COUNT_AND_SUM_FAILURE: 'GET_REFERAL_COUNT_AND_SUM_FAILURE',

    SAVE_USER_PERSONAL_ADDR_REQUEST: 'SAVE_USER_PERSONAL_ADDR_REQUEST',
    SAVE_USER_PERSONAL_ADDR_SUCCESS: 'SAVE_USER_PERSONAL_ADDR_SUCCESS',
    SAVE_USER_PERSONAL_ADDR_FAILURE: 'SAVE_USER_PERSONAL_ADDR_FAILURE',

    SAVE_SOCIAL_MEDIA_REQUEST: 'SAVE_SOCIAL_MEDIA_REQUEST',
    SAVE_SOCIAL_MEDIA_SUCCESS: 'SAVE_SOCIAL_MEDIA_SUCCESS',
    SAVE_SOCIAL_MEDIA_FAILURE: 'SAVE_SOCIAL_MEDIA_FAILURE',


    GET_USER_BY_REFCODE_REQUEST: 'GET_USER_BY_REFCODE_REQUEST',
    GET_USER_BY_REFCODE_SUCCESS: 'GET_USER_BY_REFCODE_SUCCESS',
    GET_USER_BY_REFCODE_FAILURE: 'GET_USER_BY_REFCODE_FAILURE',

    //New
    GET_USER_BY_NEW_REQUEST: 'GET_USER_BY_NEW_REQUEST',
    GET_USER_BY_NEW_SUCCESS: 'GET_USER_BY_NEW_SUCCESS',
    GET_USER_BY_NEW_FAILURE: 'GET_USER_BY_NEW_FAILURE',

    // new 
    GET_MESSAGE_CLIENT_LIST_REQUEST: 'MESSAGE_CLIENT_LIST_REQUEST',
    GET_MESSAGE_CLIENT_LIST_SUCCESS: 'MESSAGE_CLIENT_LIST_SUCCESS',
    GET_MESSAGE_CLIENT_LIST_FAILURE: 'MESSAGE_CLIENT_LIST_FAILURE:',



    ADD_REQUEST: 'ADD_REQUEST',
    ADD_SUCCESS: 'ADD_SUCCESS',
    ADD_FAILURE: 'ADD_FAILURE',

    WALLETT_REQUEST: 'WALLETT_REQUEST',
    WALLETT_SUCCESS: 'WALLETT_SUCCESS',
    WALLETT_FAILURE: 'WALLETT_FAILURE',



    DETAILS_REQUEST: 'DETAILS_REQUEST',
    DETAILS_SUCCESS: 'DETAILS_SUCCESS',
    DETAILS_FAILURE: 'DETAILS_FAILURE',

    KYC_REQUEST: 'KYC_REQUEST',
    KYC_SUCCESS: 'KYC_SUCCESS',
    KYC_FAILURE: 'KYC_FAILURE',



    SELFIE_REQUEST: 'SELFIE_REQUEST',
    SELFIE_SUCCESS: 'SELFIE_SUCCESS',
    SELFIE_FAILURE: 'SELFIE_FAILURE',

    KYCPAN_REQUEST: 'KYCPAN_REQUEST',
    KYCPAN_SUCCESS: 'KYCPAN_SUCCESS',
    KYCPAN_FAILURE: 'KYCPAN_FAILURE',


    UPLOAD_REQUEST: 'UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPLOAD_FAILURE: 'UPLOAD_FAILURE',





    USER_INFO_REQUEST: 'USER_INFO_REQUEST',
    USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
    USER_INFO_FAILURE: 'USER_INFO_FAILURE',




    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    TOGGLE_SUCCESS: 'TOGGLE_SUCCESS',


    WALLET_NAME_SUCCESS: 'WALLET_NAME_SUCCESS',

    WALLET_SUCCESS: 'WALLET_SUCCESS',

    THEME_SUCCESS: 'THEME_SUCCESS',

    LANGUAGEI_SUCCESS: 'LANGUAGEI_SUCCESS',

    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    USER_REGISTER_OTP_REQUEST: 'USER_REGISTER_OTP_REQUEST',
    USER_REGISTER_OTP_SUCCESS: 'USER_REGISTER_OTP_SUCCESS',
    USER_REGISTER_OTP_FAILURE: 'USER_REGISTER_OTP_FAILURE',

    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    USER_LOGIN_OTP_REQUEST: 'USER_LOGIN_OTP_REQUEST',
    USER_LOGIN_OTP_SUCCESS: 'USER_LOGIN_OTP_SUCCESS',
    USER_LOGIN_OTP_FAILURE: 'USER_LOGIN_OTP_FAILURE',

    FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
    FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
    FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',

    FORGET_UPDATE_PASS_REQUEST: 'FORGET_UPDATE_PASS_REQUEST',
    FORGET_UPDATE_PASS_SUCCESS: 'FORGET_UPDATE_PASS_SUCCESS',
    FORGET_UPDATE_PASS_FAILURE: 'FORGET_UPDATE_PASS_FAILURE',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    ADD_CONTRACT_ADDRESS_REQUEST: 'ADD_CONTRACT_ADDRESS_REQUEST',
    ADD_CONTRACT_ADDRESS_SUCCESS: 'ADD_CONTRACT_ADDRESS_SUCCESS',
    ADD_CONTRACT_ADDRESS_FAILURE: 'ADD_CONTRACT_ADDRESS_FAILURE',



    GET_TX_REQUEST: 'GET_TX_REQUEST',
    GET_TX_SUCCESS: 'GET_TX_SUCCESS',
    GET_TX_FAILURE: 'GET_TX_FAILURE',

    UPLOAD_PROFILE_IMG_REQUEST: 'UPLOAD_PROFILE_IMG_REQUEST',
    UPLOAD_PROFILE_IMG_SUCCESS: 'UPLOAD_PROFILE_IMG_SUCCESS',
    UPLOAD_PROFILE_IMG_FAILURE: 'UPLOAD_PROFILE_IMG_FAILURE',


    GET_ADDRESS_REQUEST: 'GET_ADDRESS_REQUEST',
    GET_ADDRESS_SUCCESS: 'GET_ADDRESS_SUCCESS',
    GET_ADDRESS_FAILURE: 'GET_ADDRESS_FAILURE',

    SET_THEME: 'SET_THEME',
    SET_LANG: 'SET_LANG',



    COMMENT_LIST_ON_VIDEO_REQUEST: "COMMENT_LIST_ON_VIDEO_REQUEST",
    COMMENT_LIST_ON_VIDEO_SUCCESS: "COMMENT_LIST_ON_VIDEO_SUCCESS",
    COMMENT_LIST_ON_VIDEO_FAILURE: "COMMENT_LIST_ON_VIDEO_FAILURE",



    SHARE_VIEW_UPDATE_VIDEO_REQUEST: "SHARE_VIEW_UPDATE_VIDEO_REQUEST",
    SHARE_VIEW_UPDATE_VIDEO_SUCCESS: "SHARE_VIEW_UPDATE_VIDEO_SUCCESS",
    SHARE_VIEW_UPDATE_VIDEO_FAILURE: "SHARE_VIEW_UPDATE_VIDEO_FAILURE",

    SHARE_VIEW_UPDATE_NEWS_REQUEST: "SHARE_VIEW_UPDATE_NEWS_REQUEST",
    SHARE_VIEW_UPDATE_NEWS_SUCCESS: "SHARE_VIEW_UPDATE_NEWS_SUCCESS",
    SHARE_VIEW_UPDATE_NEWS_FAILURE: "SHARE_VIEW_UPDATE_NEWS_FAILURE",

    ADD_COMMENT_ON_VIDEO_REQUEST: "ADD_COMMENT_ON_VIDEO_REQUEST",
    ADD_COMMENT_ON_VIDEO_SUCCESS: "ADD_COMMENT_ON_VIDEO_SUCCESS",
    ADD_COMMENT_ON_VIDEO_FAILURE: "ADD_COMMENT_ON_VIDEO_FAILURE",

};
