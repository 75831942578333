import React, { Component } from 'react';
import { alertActions, userActions } from '../../_actions';
import { connect } from 'react-redux';
import { BiX } from "react-icons/bi";
import { MdOutlineCameraAlt, MdSaveAlt } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IoMdCloseCircle } from "react-icons/io";
import { Helmet } from 'react-helmet';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabledPersonal: true,
      isDisabledAddress: true,
      isDisabledSocialMedia: true,
      fieldsUser: {},
      errorsUser: {},
      fieldsSocialMedia: {},
      errorsSocialMedia: {},
      uploadImageField: false,
      isEditing: false,
      openMode: false,
      userInfo: {
        fullName: '',
        imageLinkUrl: '',
      },
      updateProfile: false
    }
    this.fileInputRef = React.createRef();
    this.handleCameraIconClick = this.handleCameraIconClick.bind(this);
    this.handleOnChangeImageUpload = this.handleOnChangeImageUpload.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.users.isPasswordUpdate) {
      return {
        ...nextProps,
        fieldsUser: {},
        errorsUser: {},
        fieldsSocialMedia: {},
        errorsSocialMedia: {},
      }
    } else if (nextProps.users.isDisabledPersonal) {
      return {
        ...nextProps,
        isDisabledPersonal: true,
        isDisabledAddress: true,
        isDisabledSocialMedia: true,
      }
    } else {
      return {
        ...nextProps,
      }
    }
  }

  inputChange = (e) => {
    let { name, value } = e.target;
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    this.setState({ fieldsUser, errorsUser });
  }

  saveUserPersonalAddr = (e) => {
    e.preventDefault();
    if (this.handleValidationAddrInfo()) {
      let { firstName, lastName, dob } = this.state.fieldsUser;
      this.props.dispatch(userActions.saveUserInfo({
        firstName: firstName,
        lastName: lastName,
        // email: email,
        dob: dob,
      }, this.props));
    }
  }

  handleValidationAddrInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;

    if (!fieldsUser["firstName"] || !fieldsUser["firstName"].match("^[A-Za-z]+(['\\-\\.]?[A-Za-z]+)?$")) {
      formIsValid = false;
      errorsUser["firstName"] = "Please enter a valid first Name with only letters";
    }

    if (!fieldsUser["lastName"] || !fieldsUser["lastName"].match("^[A-Za-z]+(['\\-\\.]?[A-Za-z]+)?$")) {
      formIsValid = false;
      errorsUser["lastName"] = "Please enter a valid last name with only letters";
    }

    // if (!fieldsUser["email"] || !fieldsUser["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    //   formIsValid = false;
    //   errorsUser["email"] = "Please enter a valid email";
    // }

    if (!fieldsUser["dob"] || !fieldsUser["dob"]) {
      formIsValid = false;
      errorsUser["dob"] = "Please enter a valid dob";
    }

    this.setState({ errorsUser: errorsUser });
    return formIsValid;
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfile());
    window.scrollTo(0, 0);
  }

  updateProfileAddress = () => {
    let { users } = this.props;
    let { getProfile } = users;
    this.setState({
      fieldsUser: getProfile,
      isDisabledAddress: false,
    });
  }

  cancelProfileAddress = () => {
    this.setState({
      isDisabledAddress: true,
      fieldsUser: {}
    });
  }



  handleAddPhotoClick = () => {
    this.fileInputRef.current.click();
  };

  uploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState((prevState) => ({
          userInfo: {
            ...prevState.userInfo,
            imageLinkUrl: reader.result,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ updateProfile: true });
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo,
        [name]: value,
      },
    }));
  };


  handleOnChangeImageUpload = (e) => {
    let value = "save"

    const file = e.target.files[e.target.files.length - 1]; // Get the uploaded file
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      this.props.dispatch(userActions.uploadImage2(formData, value))
    }
  };
  handleSaveButtonClick = () => {
    const { users } = this.props;
    const { filesDetailsSelfie } = users
    if (filesDetailsSelfie?.imageURL) {
      const data = {
        profileUrl: filesDetailsSelfie.imageURL
      }
      this.props.dispatch(userActions.updateProfilePicture(data))
      this.setState({ updateProfile: false })
    }
    else {
      this.props.dispatch(alertActions.error("Profile Picture Not Update"))
    }
  };

  handleClick = () => {
    this.props.dispatch(userActions.clearImage())
    this.setState({ updateProfile: true, openMode: false });
    this.fileInputRef?.current?.click()
  }
  handleClick2 = () => {
    this.props.dispatch(userActions.clearImage())
    this.setState({ updateProfile: false, openMode: false })
    this.props.dispatch(userActions.removeImage())
  }

  handleCameraIconClick = () => {
    this.setState({ openMode: !this.state.openMode });


    // confirmAlert({
    //   title: 'Update Image',
    //   // message: 'Are you sure you want to logout..',
    //   buttons: [
    //     {
    //       label: 'Upload Image',
    //       onClick: () => this.handleClick()
    //     },
    //     {
    //       label: 'Remove',
    //       onClick: () => this.handleClick2()
    //     }
    //   ]
    // });


  };





  render() {
    let { users } = this.props;
    let { getProfile, loadingImg } = users;
    const today = new Date().toISOString().split('T')[0];
    const { isEditing, userInfo,
    } = this.state;

    console.log(loadingImg);

    const fullName = (getProfile && getProfile.user.firstName ? getProfile?.user?.firstName + " " : "") +
      (getProfile && getProfile?.user?.lastName ? getProfile.user.lastName + "" : "");
    return (
      <>
        <Helmet>
          <title>VuneCoin - profile</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <section className='overflow-hidden overflow-y-auto  lg:pb-0 pb-20'>
          <div className="mx-auto w-11/12">
            <div className='flex justify-between p-6'>
              <div>
                <p className='text-[26px] font-[700] text-[#ffffff]'>Profile</p>

              </div>


            </div>
            {
              this.state.isDisabledAddress === true ? <div className='text-colour border border-[#5B4DCA] rounded-lg shadow-lg ' id='profile'>
                <div className="flex flex-col px-6 py-4 ">
                  <div className="lg:flex items-center lg:space-x-2 space-x-0 lg:space-y-0 space-y-2">
                    <div className="relative">
                      {loadingImg ? <div className="w-24 h-24 rounded-full ring-2 ring-[#5B4DCA] flex items-center justify-center">
                        <div role="status">
                          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div> : <img
                        src={users?.filesDetailsSelfie && users?.filesDetailsSelfie?.imageURL ? users.filesDetailsSelfie.imageURL : (getProfile && getProfile.user.profileUrl ? getProfile.user.profileUrl : getProfile && getProfile.user.profileUrl) || "https://res.cloudinary.com/dnwwwqhgb/image/upload/v1713271484/your_folder_name/fdhq1hyf7j3hxn98yriv.png"}
                        onError={(e) => { e.target.src = "https://res.cloudinary.com/dnwwwqhgb/image/upload/v1713271484/your_folder_name/fdhq1hyf7j3hxn98yriv.png" }}
                        className="w-24 h-24 rounded-full ring-2 ring-[#5B4DCA]"
                        alt=""
                      />}
                      <div className="absolute bottom-0 left-16 bg-transparent rounded-full p-0.5">
                        <input
                          type="file"
                          accept="image/*"
                          ref={this.fileInputRef}
                          onChange={this.handleOnChangeImageUpload}
                          style={{ display: 'none' }}
                        />

                        <MdOutlineCameraAlt
                          className="h-6 w-6 text-white"
                          onClick={this.handleCameraIconClick}
                        />

                      </div>
                      {this.state.openMode && <div className=' absolute top-22 left-10 rounded-sm p-4 flex flex-col gap-3 whitespace-nowrap bg-white  text-gray-700 '>
                        <div className=' relative flex flex-col px-3 p-1 gap-3 whitespace-nowrap ' >
                          <div className=' cursor-pointer' onClick={() => this.handleClick()} >Upload Image</div>
                          {/* <div  className=' w-full h-[1px]  bg-gray-700'></div> */}
                          <div className='cursor-pointer' onClick={() => this.handleClick2()}  >Remove Image</div>
                        </div>
                        <IoMdCloseCircle onClick={() => this.setState({ openMode: false })} className=' cursor-pointer absolute top-1 right-1 text-lg' />
                      </div>}

                    </div>

                    <div className={isEditing ? "flex flex-col" : "flex flex-col space-y-3"}>
                      {isEditing ?
                        <input
                          type="text"
                          className="py-2 px-2 w-full shadow-sm rounded bg-white"
                          name="fullName"
                          value={fullName}
                          onChange={this.handleOnChange}
                        />
                        :
                        <div className="font-bold text-[#ffff]">
                          {" "} {fullName}
                        </div>
                      }
                      {/* {this.state.updateProfile && <button className='bg-[#641BAC] rounded px-3 py-1 text-white' onClick={this.handleSaveButtonClick}>
                        Save
                      </button>}  */}

                    </div>
                  </div>
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-6 p-10'>
                  <div className='w-full flex flex-col space-y-3 '>
                    <label className='text-[18px] font-[400] text-[#fff]' htmlFor="">First name</label>
                    <input className='block w-full px-4 py-3 leading-tight text-[#fff] text-[16px] font-[400] bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none' type="text" name="firstName" disabled value={getProfile && getProfile.user.firstName ? getProfile.user.firstName + "" : ""} />
                  </div>
                  <div className='w-full flex flex-col space-y-3'>
                    <label className='text-[18px] font-[400] text-[#fff]' htmlFor="">Last name</label>
                    <input className='block w-full px-4 py-3 leading-tight text-[#fff] text-[16px] font-[400] bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none' disabled type="text" name="lastName" value={getProfile && getProfile.user.lastName ? getProfile.user.lastName + "" : ""} />
                  </div>
                  <div className='w-full flex flex-col space-y-3'>
                    <label className='text-[18px] font-[400] text-[#fff]' htmlFor="">Mobile number</label>
                    <input className='block w-full px-4 py-3 leading-tight text-[#fff] text-[16px] font-[400] bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none' disabled type="text" name="mobNo" value={(getProfile && getProfile.user.dialcode ? getProfile.user.dialcode + "" : "") + (getProfile && getProfile.user.mobNo ? getProfile.user.mobNo + "" : "")} />
                  </div>
                  <div className='w-full flex flex-col space-y-3'>
                    <label className='text-[18px] font-[400] text-[#fff]' htmlFor="">Email address</label>
                    <input className='block w-full px-4 py-3 leading-tight text-[#fff] text-[16px] font-[400] bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none' disabled type="text" name="email" value={getProfile && getProfile.user.email ? getProfile.user.email + "" : ""} />
                  </div>
                </div>

              </div>
                :
                null
            }
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  return {
    users,
    user: overview ? overview.user : null,
  };
}

export default connect(mapStateToProps)(Dashboard);
