import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Menu } from '@headlessui/react'
import { FaRegUserCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdKeyboardArrowDown, MdSupportAgent, MdNotificationsNone } from "react-icons/md";
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TbUserCog } from "react-icons/tb";
import { AiOutlineLogout } from "react-icons/ai";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: false,
      notificationCount: props.users?.getProfile?.notificationCount || "",
    }
  }

  logout = (url) => {
    window.sessionStorage.removeItem('user');
    this.props.history.push(url);
    setTimeout(() => {
    }, 150);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfile());
  }

  logoutSubmit = (data) => {
    confirmAlert({
      title: 'Are you sure you want to logout?',
      // message: 'Are you sure you want to logout..',
      buttons: [
        {
          label: 'Logout',
          onClick: () => this.logout('/login')
        },
        {
          label: 'Cancel'
        }
      ],
      overlayClassName: 'custom-overlay'
    });
  }

  classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
    console.log('this.props____:', this.props);
  }

  handleOpen = () => {
    this.setState({ activeTab: true });
  };

  handleFalse = () => {
    this.setState({ activeTab: false });
  };

  handleClearNotification = () => {
    this.setState({ notificationCount: "" }); // or set to 0 if preferred
  };

  render() {

    let { users } = this.props;
    let { getProfile } = users;
    const { activeTab } = this.state;
    const imageSrc = getProfile && getProfile.user && getProfile.user.profileUrl;
    console.log('getProfileDataPigetProfileDataPigetProfileDataPi', this.state.notificationCount);
    console.log(getProfile?.notificationCount)

    return (
      <>

        <div className="relative z-10 h-18 ">
          <div className="flex items-center justify-between px-4 xl:py-8 py-2">
            <div className="w-full">

            </div>
            {/* <div className="w-full">
              <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
              <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
                </div>
                <input type="search" id="default-search" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
              </div>
            </div> */}
            <div className="flex items-center justify-end py-1 w-full">
              <div onClick={this.handleClearNotification}>
                <button onClick={() => this.onClickMenu('/app/notification')} class="relative bg-[#6514A6] shadow-lg duration-300 p-0.5 text-blue-100 rounded-full mt-0.5"><MdNotificationsNone className="w-8 h-8 text-[#fff] relative" />
                  {this.state.notificationCount ? <span className="absolute -top-2 left-6 bg-white flex justify-center items-center text-xs rounded-full h-6 w-6 text-red-500"> {this.state.notificationCount || ""}</span>
                    :
                    ""}
                </button>
              </div>
              <Menu as="div" className="relative flex items-center space-x-1 text-left lg:space-x-4">
                {({ open }) => (
                  <>
                    <div className="relative pr-2 lg:ml-6" >
                      <Menu.Button className="flex items-center max-w-xs text-sm rounded-full lg:space-x-1 focus:outline-none focus:shadow-outline">
                        {imageSrc ? (
                          <img className="w-12 h-12 rounded-full" src={getProfile && getProfile.user && getProfile.user.profileUrl ? getProfile.user.profileUrl : ''} alt={getProfile && getProfile.user && getProfile.user.image ? getProfile.user.image : ''} />
                          // <FaRegUserCircle className="text-[#ffff] w-8 h-8" />
                        ) : (
                          <img src="/img/Image.png" alt="profile" />
                        )}
                        <p className="text-[#FFFFFF] text-[10px] font-[500]" translate="no">{getProfile && getProfile.user && getProfile.user.firstName ? getProfile.user.firstName : ''} {getProfile && getProfile.user && getProfile.user.lastName ? getProfile.user.lastName : ''}</p>
                        <MdKeyboardArrowDown className="text-[#ffff]" size={25} />
                      </Menu.Button>
                    </div>
                    <Transition show={open} as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items static className="absolute right-0 mt-2 font-medium origin-top-right bg-white rounded-md w-44 top-8 shadow-3xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) =>
                            (<span onClick={() => this.logoutSubmit('/login')} className={this.classNames(active ? 'bg-red-500 text-red-500 hover:bg-red-400 hover:text-white' : 'text-red-500 ', 'transition ease-in-out duration-500 cursor-pointer px-4 py-2 font-semibold text-sm flex items-center')}><span className="pr-3"><AiOutlineLogout className="w-5 h-5" /></span> Logout</span>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>

      </>
    )
  }
}

function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;

  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}

export default withRouter(connect(mapStateToProps)(Header));