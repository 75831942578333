import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { MdMail } from 'react-icons/md';
import { AiFillFacebook, AiFillRedditSquare, AiFillTwitterSquare, AiFillMediumSquare, AiFillLinkedin } from 'react-icons/ai';
import { SiTelegram, SiBitcoin } from 'react-icons/si';
import { FaYoutubeSquare, FaInstagramSquare } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { Helmet } from 'react-helmet';

class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldContact: {},
      errorsContact: {},
      shown: false,
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  componentDidMount() {
    // this.props.dispatch(userActions.statics());
  }


  inputChange = (event) => {
    event.preventDefault();
    const { fieldContact, errorsContact } = this.state;
    fieldContact[event.target.name] = event.target.value;
    errorsContact[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ fieldContact, errorsContact });
  }

  createNotificationSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationContact()) {
      let reqData = {
        name: this.state.fieldContact.name,
        email: this.state.fieldContact.email,
        mobNo: this.state.fieldContact.mobNo,
        msg: this.state.fieldContact.msg
      }
      this.props.dispatch(userActions.createNotification(reqData, this.props));
    }
  }

  handleValidationContact = () => {
    let fieldContact = this.state.fieldContact;
    let errorsContact = {};
    let formIsValid = true;

    // name
    if (!fieldContact["name"]) {
      formIsValid = false;
      errorsContact["name"] = "Please Enter Name!";
    }

    // email
    if (!fieldContact["email"]) {
      formIsValid = false;
      errorsContact["email"] = "Please Enter Email!";
    }

    // mobNo
    if (!fieldContact["mobNo"]) {
      formIsValid = false;
      errorsContact["mobNo"] = "Please Enter Mobile Number!";
    }

    // msg
    if (!fieldContact["msg"]) {
      formIsValid = false;
      errorsContact["msg"] = "Please Enter Message!";
    }


    this.setState({ errorsContact: errorsContact });
    return formIsValid;
  }


  render() {
    //let classes = {};
    // let { authentication } = this.props;
    //let { statics} = users;
    return (
      <>
      <Helmet>
          <title>VuneCoin - Contact us</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <div className='h-screen overflow-y-auto'>

        
          {/* Header section */}
          <div data-aos="fade-down" className="w-full bg-[#29092a] h-24 sticky top-0 z-20">
            <div className="flex justify-between items-center max-w-6xl xl:px-0 md:px-10 px-4 h-full text-white mx-auto relative">

              <div>
                <div className='cursor-pointer' onClick={() => this.onClickMenu('/')}>
                  <img src="/imglogo/GPT_Final Logo_For Dark BG.png" alt="" className="md:w-44 w-36 bg-contain" />
                </div>
              </div>


              {this.state.shown ? <div className="md:flex   md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-[#29092a]  ">
                <ul className="text-white md:flex font-semibold uppercase md:space-x-8 md:space-y-0 space-y-4 md:p-0 p-6 md:text-left text-center">
                  <li className=" cursor-pointer " onClick={() => this.onClickMenu('/')}>Home</li>
                  <li className=" cursor-pointer" onClick={() => this.onClickMenu('/aboutus')}>About</li>
                  <li className=" cursor-pointer text-orange-500" onClick={() => this.onClickMenu('/contactus')}>contact</li>
                  <li className=" cursor-pointer" onClick={() => this.onClickMenu('/login')}>login</li>
                  <li className=" cursor-pointer" onClick={() => this.onClickMenu('/signup')}>Sign Up</li>
                </ul>
              </div> :
                <div className="md:flex hidden   md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-[#29092a]  ">
                  <ul className="text-white md:flex font-semibold uppercase md:space-x-8 md:space-y-0 space-y-4 md:p-0 p-6 md:text-left text-center">
                    <li className=" cursor-pointer " onClick={() => this.onClickMenu('/')}>Home</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/aboutus')}>About</li>
                    <li className=" cursor-pointer text-orange-500" onClick={() => this.onClickMenu('/contactus')}>contact</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/login')}>login</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/signup')}>Sign Up</li>
                  </ul>
                </div>}

              <FiMenu onClick={() => this.setState({ shown: !this.state.shown })} className="text-white text-[1.5rem] md:hidden flex" />

            </div>
          </div>

          <div className="w-full bg-[#0d020e] h-80 bg-cover" style={{ backgroundImage: `url("contactus/ContactBanner.png")` }}>

          </div>

          <div className="w-full " style={{ backgroundImage: `url("contactus/Section2BG.png")` }}>
            <section className="2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto md:flex items-center md:space-y-0 space-y-2">

              <div className="w-full">
                <div className=" max-w-md my-24  bg-gradient-to-b from-[#aa3446] to-[#9f2552] p-8  shadow-xl">
                  <form action="#">
                    <div className="mt-5 w-full">
                      <label for="username" className='text-xl font-semibold text-white tracking-wider'>Send a Message...</label>
                      <input type="text" placeholder="Your Name" className="block w-full py-2 px-4 focus:outline-none mt-1 font-normal placeholder-text-black " name='name'
                        value={this.state.fieldContact && this.state.fieldContact['name'] ? this.state.fieldContact['name'] : ''} onChange={this.inputChange} />
                      {this.state.errorsContact["name"] ?
                        <div className="invalid-feedback text-yellow-400">
                          {this.state.errorsContact["name"]}
                        </div>
                        : null}
                    </div>
                    <div className="mt-5 w-full">
                      <input type="email" placeholder="You Email Address" className="block w-full py-2 px-4 focus:outline-none"
                        name='email' value={this.state.fieldContact && this.state.fieldContact['email'] ? this.state.fieldContact['email'] : ''} onChange={this.inputChange} />
                      {this.state.errorsContact["email"] ?
                        <div className="invalid-feedback text-yellow-400">
                          {this.state.errorsContact["email"]}
                        </div>
                        : null}
                    </div>
                    <div className="mt-5 w-full">
                      <input type="text" placeholder="You Phone Number" className="block w-full py-2 px-4 focus:outline-none" name='mobNo' value={this.state.fieldContact && this.state.fieldContact['mobNo'] ? this.state.fieldContact['mobNo'] : ''} onChange={this.inputChange} />
                      {this.state.errorsContact["mobNo"] ?
                        <div className="invalid-feedback text-yellow-400">
                          {this.state.errorsContact["mobNo"]}
                        </div>
                        : null}
                    </div>
                    <div className="mt-5 w-full">
                      <textarea type="text" cols="8" rows="5" placeholder="Your Message" className="block w-full py-2 px-4 focus:outline-none" name='msg' value={this.state.fieldContact && this.state.fieldContact['msg'] ? this.state.fieldContact['msg'] : ''} onChange={this.inputChange} />
                      {this.state.errorsContact["msg"] ?
                        <div className="invalid-feedback text-yellow-400">
                          {this.state.errorsContact["msg"]}
                        </div>
                        : null}
                    </div>
                    <div className="my-4 pb-4">
                      <button className="py-3 bg-[#ff8e2a] text-white rounded font-semibold text-lg text-center w-full" type="button" onClick={this.createNotificationSubmit}>Send Email</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="w-full flex md:justify-end justify-center">
                <img src="contactus/contactloti.png" alt="Section02_Graphics ?" title="What is Altcoin ?" className="w-5/6" />
              </div>
            </section>
          </div>


          {/* footer section  */}
          <section className="w-full   bg-cover" style={{ backgroundImage: `url("newImg/FOOTER.png")` }}>
            <footer className="pt-20" >
              <div className=" 2xl:w-8/12 w-5/6  mx-auto lg:flex  py-10 lg:space-x-20 lg:space-y-0 space-y-10 justify-between ">

                <div className="lg:w-6/12 w-full">
                  <div className="space-y-4">
                    <img src="/imglogo/GPT_Final Logo_For Dark BG.png" alt="" className="w-48 bg-contain flex justify-center" />
                    <h2 className="text-left text-white  text-sm ">Bit-Learn provides clients with the industry’s broadest range of solutions to meet their investment objectives, continued investment in technology and our ambition is to give more people access to financial markets.</h2>
                  </div>
                </div>


                <div className="lg:w-3/12 w-full">
                  <h1 className="uppercase font-semibold text-white text-2xl py-4 " >Social media</h1>
                  <div className="flex flex-wrap gap-4   text-white">

                    <a className="cursor-pointer" href='https://www.facebook.com/Efin-Cap-101877035960050' target="blank" > <AiFillFacebook className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://www.reddit.com/user/EFinCap' target="blank" > <AiFillRedditSquare className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://twitter.com/CaptialEfin' target="blank" ><AiFillTwitterSquare className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://t.me/EFinCap' target="blank" > <SiTelegram className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://medium.com/@Bit-Learn' target="blank" ><AiFillMediumSquare className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://www.instagram.com/GPT_tech/' target="blank" ><FaInstagramSquare className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://bitcointalk.org/index.php?action=profile;u=3493952;sa=showPosts' target="blank" ><SiBitcoin className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://www.linkedin.com/in/efin-captial-384b47246/?original_referer=http%3A%2F%2F127.0.0.1%3A5555%2F' target="blank" ><AiFillLinkedin className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://www.youtube.com/channel/UCum_a5LW2IWHfJ8WMJ0fS5A' target="blank" ><FaYoutubeSquare className="text-[1.5rem]" /></a>


                  </div>
                </div>

                <div className="lg:w-3/12 text-white w-full ">
                  <h1 className="uppercase text-2xl text-white py-4" >contact us</h1>

                  <div className="space-y-2" >
                    <div className="flex items-center space-x-2" > <MdMail className="text-[1.5rem]" /> <p className="text-white text-base" >info@Bit-Learn.io</p> </div>
                    <div className="flex items-center space-x-2" ><MdMail className="text-[1.5rem]" /> <p className="text-white text-base" >support@Bit-Learn.io</p></div>
                  </div>

                </div>

              </div>

              <div className="text-white text-sm py-2 text-center bg-white/10">© 2022 All Rights Reserved</div>

            </footer>
          </section>


        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Contactus);
